import { CordraObject } from "@cnri/cordra-client";
import { ObjectPreviewUtil, PreviewData } from '../ObjectPreviewUtil';

interface SearchResultConfig {
    includeType?: boolean;
    includeModifiedDate?: boolean;
    includeCreatedDate?: boolean;
}

export class SearchResult {
    constructor(
            containerDiv: JQuery<HTMLDivElement>,
            result: CordraObject,
            uiConfig: { searchResults?: SearchResultConfig },
            onHandleClick: (e: JQuery.ClickEvent) => void
    ) {
        const resultCard = $(`
            <div class="search-result">
                <div class="row">
                    <div id="preview" class="col-md-9">
                        <div id="header">
                            <a id="headerLink" style="display: block;font-size:18px" target="_blank"></a>
                        </div>
                        <div id="details" class="details"></div>
                    </div>
                    <div id="metadata" class="metadata col-md-3"></div>
                </div>
            </div>
        `);

        containerDiv.append(resultCard);

        const details = resultCard.find('#details');
        const metadata = resultCard.find('#metadata');


        const headerLink = resultCard.find('#headerLink');
        headerLink.text(result.id!);
        headerLink.attr("data-handle", result.id!);
        headerLink.attr("href", "#objects/" + result.id);
        if (onHandleClick) headerLink.on("click", onHandleClick);

        const searchResultsConfig: SearchResultConfig = uiConfig?.searchResults ? uiConfig.searchResults : {};

        const objectIdSpan = $('<span style="display:block"></span>');
        objectIdSpan.append('<i class="fa fa-bullseye"></i>');
        const objectIdLink = $('<a class="list-handles-link" target="_blank">');
        objectIdLink.attr("href", "#objects/" + result.id);
        objectIdLink.text(result.id!);
        objectIdLink.attr("data-handle", result.id!);
        if (onHandleClick) objectIdLink.on("click", onHandleClick);
        objectIdSpan.append(objectIdLink);
        details.append(objectIdSpan);

        if (searchResultsConfig.includeType) {
            const objectTypeSpan = $('<span style="display:block"></span>');
            objectTypeSpan.append('<i class="fa fa-file-alt"></i>');
            objectTypeSpan.append("Type: " + result.type);
            details.append(objectTypeSpan);
        }

        const previewData = this.getPreviewData(result);

        for (const jsonPointer in previewData) {
            const thisPreviewData = previewData[jsonPointer];

            const prettifiedPreviewData = this.prettifyPreviewJson(thisPreviewData.previewJson);
            if (!prettifiedPreviewData) continue;

            if (thisPreviewData.isPrimary) {
                headerLink.text(prettifiedPreviewData);
            } else if (thisPreviewData.excludeTitle) {
                if (thisPreviewData.isUri) {
                    const link = $('<a style="display:block" target="_blank"></a>');
                    link.text(prettifiedPreviewData);
                    link.attr("href", prettifiedPreviewData);
                    details.append(link);
                } else {
                    details.text(prettifiedPreviewData);
                }
            } else if (thisPreviewData.isUri) {
                const link = $('<a style="display:block" target="_blank"></a>');
                link.text(prettifiedPreviewData);
                link.attr("href", prettifiedPreviewData);
                details.text(thisPreviewData.title + ": ");
                details.append(link);
            } else {

                const infoHeaderSpan = $('<span style="display:block"><span>').text(
                    thisPreviewData.title + ": " + prettifiedPreviewData
                );
                details.append(infoHeaderSpan);
            }
        }


        if (searchResultsConfig.includeModifiedDate) {
            const modifiedOn = $('<span style="display:block; float:right; padding-right: 10px;"><span>')
                .text("Modified: " + this.toDateString(result.metadata!.modifiedOn));
            metadata.append(modifiedOn);
        }
        if (searchResultsConfig.includeCreatedDate) {
            const createdOn = $('<span style="display:block; float:right; padding-right: 10px;"><span>')
                .text("Created: " + this.toDateString(result.metadata!.createdOn));
            metadata.append(createdOn);
        }
    }

    getPreviewData(obj: CordraObject): Record<string, PreviewData> {
        return ObjectPreviewUtil.getPreviewData(obj);
    }

    prettifyPreviewJson(previewJson: unknown, maxLength?: number): string {
        let res;
        if (typeof previewJson === "string") {
            res = previewJson;
        } else {
            res = JSON.stringify(previewJson);
        }
        if (maxLength) {
            if (res.length > maxLength) {
                res = res.substring(0, maxLength) + "...";
            }
        }
        return res;
    }

    toDateString(timestamp: number): string {
        return new Date(timestamp).toISOString();
    }
}
