import { DataSet } from 'vis-data';
import { Network, IdType } from 'vis-network';
import { CordraObject, SearchResults } from '@cnri/cordra-client';
import { JsonSchema } from 'tv4';
import { ObjectPreviewUtil } from '../ObjectPreviewUtil';
import { SchemaExtractorFactory } from './SchemaExtractor';
import { SchemaUtil } from './SchemaUtil';
import { JsonUtil } from './JsonUtil';

interface NetworkNode {
    id: string;
    label?: string;
    level?: number;
    color?: { background: string };
    searchResult?: CordraObject;
    y?: number;
    allowedToMoveY?: boolean;
}

interface NetworkEdge {
    id: string;
    from: string;
    to: string;
    style: string;
    jsonPointer: string;
    level?: number;
    label?: string;
}

interface RelationshipResponse {
    nodes: NetworkNode[];
    edges: NetworkEdge[];
    results: Record<string, CordraObject>;
}

export class RelationshipsGraph {
    private nodes?: DataSet<NetworkNode>;
    private edges?: DataSet<NetworkEdge>;
    private network?: Network;

    private readonly instructions: JQuery;
    private referrersLink?: JQuery;

    private readonly canvasDiv: JQuery;
    private isBig: boolean = false;

    private existingEdges: Record<string, boolean> = {};
    private existingNodes: Record<string, NetworkNode> = {};

    private currentSelectedNode?: string;
    private readonly selectedDetails: JQuery;

    private readonly fanOutAllButton: JQuery;
    private readonly fanOutSelectedButton: JQuery;
    private readonly inboundToggleButton: JQuery;
    private outboundOnly: boolean = true;

    private readonly undoFanOutButton: JQuery;
    private readonly containerDiv: JQuery;
    private objectId: string;

    constructor(containerDiv: JQuery, objectId: string) {
        this.containerDiv = containerDiv;
        this.objectId = objectId;
        const actionToolBar = $('<div class="action-tool-bar pull-right"></div>');
        this.containerDiv.append(actionToolBar);

        const graphActionsButtonGroup = $('<div class="btn-group"></div>');
        actionToolBar.append(graphActionsButtonGroup);

        const graphActionsDropDownButton = $(
            '<button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-toggle="dropdown"><i class="fa fa-clipboard-list"></i>Graph Actions...<span class="caret"></span></button>'
        );
        graphActionsButtonGroup.append(graphActionsDropDownButton);

        const graphActionsMenu = $('<ul class="dropdown-menu"></ul>');
        graphActionsButtonGroup.append(graphActionsMenu);

        this.inboundToggleButton = $(
            '<li><a class="dropdown-item"><i class="fa fa-arrow-right"></i>Restart with inbound links</a></li>'
        );
        graphActionsMenu.append(this.inboundToggleButton);
        this.inboundToggleButton.on("click", () => this.inboundToggleButtonClick());

        this.fanOutSelectedButton = $(
            '<li><a class="dropdown-item"><i class="fa fa-compress"></i>Fan Out</a></li>'
        );
        graphActionsMenu.append(this.fanOutSelectedButton);
        this.fanOutSelectedButton.on("click", () => this.onFanOutSelectedClick());

        this.fanOutAllButton = $(
            '<li><a class="dropdown-item"><i class="fa fa-expand-arrows-alt"></i>Fan Out All</a></li>'
        );
        graphActionsMenu.append(this.fanOutAllButton);
        this.fanOutAllButton.on("click", () => this.onFanOutAllClick());

        this.undoFanOutButton = $(
            '<li><a class="dropdown-item"><i class="fa fa-compress-arrows-alt"></i>Undo Fan Out</a></li>'
        );
        graphActionsMenu.append(this.undoFanOutButton);
        this.undoFanOutButton.on("click", () => this.deleteLastAddedItems());

        this.instructions = $(
            "<div>Click and drag to manipulate graph. Double click to load object.</div>"
        );
        this.containerDiv.append(this.instructions);

        this.addReferrersLinkIfNeeded();

        const clearFix = $('<div class="clearfix"></div>');
        this.containerDiv.append(clearFix);

        this.canvasDiv = $('<div style="height:730px"></div>');
        this.containerDiv.append(this.canvasDiv);
        this.canvasDiv.css("visibility", "hidden");

        this.selectedDetails = $('<div style="margin:5px 5px 0 10px"></div>');
        this.containerDiv.append(this.selectedDetails);

        const requestedLevel = 1;
        APP.getRelationships(
            objectId,
            (response: RelationshipResponse) => {
                this.addNewRelationshipsToGraph(response, requestedLevel);
            },
            (err: unknown) => this.onGotRelationshipsError(err),
            this.outboundOnly
        );
    }

    destroy(): void {
        if (this.network) this.network.destroy();
    }

    addReferrersLinkIfNeeded(): void {
        if (this.referrersLink) {
            this.referrersLink.remove();
            delete this.referrersLink;
        }
        const referrersQuery = "internal.pointsAt:" + this.objectId;
        APP.search(
            referrersQuery,
            0,
            1,
            undefined,
            (response: SearchResults<string | CordraObject>) => {
                APP.notifications.clear();
                if (response.size !== 0) {
                    this.addReferrersLink();
                }
            },
            APP.onErrorResponse
        );
    }

    addReferrersLink(): void {
        const referrersLink = $("<span/>");
        referrersLink.append("<br/>");
        referrersLink.append("There are objects which refer to this object.  ");
        const link = $('<a href="#">Click here</a>');
        referrersLink.append(link);
        referrersLink.append(" to list them.");
        this.referrersLink = referrersLink;
        this.instructions.append(referrersLink);
        link.on("click", (e) => {
            e.preventDefault();
            const referrersQuery = "internal.pointsAt:" + this.objectId;
            APP.performSearchWidgetSearch(referrersQuery, undefined);
        });
    }

    getCurrentTopLevel(): number {
        if (!this.nodes || !this.edges) return -1;
        let topLevel = 0;
        const nodeIds = this.nodes.getIds();
        for (const nodeId of nodeIds) {
            const node = this.nodes.get(nodeId) as NetworkNode;
            if (node.level && node.level > topLevel) {
                topLevel = node.level;
            }
        }

        const edgeIds = this.edges.getIds();
        for (const edgeId of edgeIds) {
            const edge = this.edges.get(edgeId) as NetworkEdge;
            if (edge.level && edge.level > topLevel) {
                topLevel = edge.level;
            }
        }
        return topLevel;
    }

    buildNetworkDynamicLayout(): void {
        if (!this.nodes || !this.edges) return;
        this.nodes.add({ id: "fakeNode" });
        const data = {
            nodes: this.nodes,
            edges: this.edges
        };
        const options = {
            physics: {
                barnesHut: {
                    gravitationalConstant: -4250,
                    centralGravity: 0.05,
                    springConstant: 0.002,
                    springLength: 500
                },
                stabilization: {
                    iterations: 1500
                }
            },
            nodes: {
                shape: "box"
            },
            edges: {
                arrows: "to",
                length: 500
            }
        };

        const network = new Network(this.canvasDiv.get(0)!, data, options);
        network.on("select", (props: { nodes: string[] }) => this.onSelect(props));
        network.on("doubleClick", (props: { nodes: string[] }) => this.doubleClick(props));
        network.selectNodes([this.objectId]);
        this.currentSelectedNode = this.objectId;
        this.displaySelectedNodeData(this.currentSelectedNode);

        network.on("stabilized", () => {
            this.canvasDiv.css("visibility", "visible");
        });
        this.network = network;

        setTimeout(() => {
            this.nodes?.remove(["fakeNode"]);
        }, 1);

        this.animatedZoomExtent();
    }

    animatedZoomExtent(): void {
        const intervalId = setInterval(() => {
            this.network?.fit();
        }, 1000 / 60);

        this.network?.once("stabilized", () => {
            clearInterval(intervalId);
            this.network?.fit({ animation: { duration: 200, easingFunction: 'linear' } });
        });

        setTimeout(() => {
            clearInterval(intervalId);
        }, 5000);
    }

    onCloseClick(): void {

    }

    addNewRelationshipsToGraph(res: RelationshipResponse, requestedLevel: number, zoomExtent: boolean = false): void {
        const nodesToAdd = [];
        for (const node of res.nodes) {
            if (this.existingNodes[node.id]) {
                continue;
            }
            this.existingNodes[node.id] = node;
            if (node.id === this.objectId) {
                node.level = 0;
            } else {
                node.level = requestedLevel;
            }

            nodesToAdd.push(node);
            if (node.id === this.objectId) {
                node.color = { background: "Silver" };
            }
            const searchResult = res.results[node.id];
            node.searchResult = searchResult;
            if (searchResult != null) {
                this.addPreviewDataToNode(node, searchResult);
            }
        }

        this.addLabelsToEdges(res.edges, res.results);

        if (!this.network || !this.edges || !this.nodes) {
            this.nodes = new DataSet();
            this.edges = new DataSet();
            if (res.nodes.length === 2) {
                this.setInitialPositionForNodes(res.nodes);
            }
            this.nodes.add(nodesToAdd);
            this.addEdges(res.edges, requestedLevel);
            this.buildNetworkDynamicLayout();
        } else {
            this.nodes.add(nodesToAdd);
            this.addEdges(res.edges, requestedLevel);
            if (zoomExtent) {
                this.animatedZoomExtent();
            }
        }
    }

    doubleClick(properties: { nodes: string[] }): void {
        const selectedNodes = properties.nodes;
        if (selectedNodes.length > 0) {
            const firstSelectedNodeId = selectedNodes[0];
            APP.resolveHandle(firstSelectedNodeId);
        }
    }

    inboundToggleButtonClick(): void {
        this.inboundToggleButton.trigger("blur");
        if (this.outboundOnly) {
            this.inboundToggleButton
                .children(":first-child")
                .empty()
                .append(
                    '<i class="fa fa-arrow-left"></i>Restart with outbound links only'
                );
            this.outboundOnly = false;
            this.resetNetworkByPrune();
        } else {
            this.inboundToggleButton
                .children(":first-child")
                .empty()
                .append(
                    '<i class="fa fa-arrow-right"></i>Restart with inbound links'
                );
            this.outboundOnly = true;
            this.resetNetworkByPrune();
        }
    }

    resetNetworkByPrune(): void {
        this.pruneBackToLevel1OutboundOnly();

        if (!this.outboundOnly) {
            // get relationships for the root node
            const requestedLevel = 1;
            const zoomExtent = true;
            APP.getRelationships(
                this.objectId,
                (response: RelationshipResponse) => {
                    this.addNewRelationshipsToGraph(response, requestedLevel, zoomExtent);
                },
                (err: unknown) => this.onGotRelationshipsError(err),
                this.outboundOnly
            );
        }
    }

    deleteLastAddedItems(): void {
        if (!this.nodes || !this.edges) return;
        this.undoFanOutButton.trigger("blur");
        const currentTopLevel = this.getCurrentTopLevel();
        if (currentTopLevel === 1) return;

        const nodeIds = this.nodes.getIds();
        const nodesToDelete = [];

        for (const nodeId of nodeIds) {
            const node = this.nodes.get(nodeId) as NetworkNode;
            if (node.level === currentTopLevel) {
                nodesToDelete.push(nodeId);
            }
        }

        const edgeIds = this.edges.getIds();
        const edgesToDelete = [];

        for (const edgeId of edgeIds) {
            const edge = this.edges.get(edgeId) as NetworkEdge;
            if (edge.level === currentTopLevel) {
                edgesToDelete.push(edgeId);
            }
        }

        this.removeNodes(nodesToDelete);
        this.removeEdges(edgesToDelete);
    }

    // get the level 0 node
    // find its outbound links
    // find the nodes those links point at.
    // delete all nodes and links not in the above
    pruneBackToLevel1OutboundOnly(): void {
        if (!this.nodes || !this.edges) return;
        const rootObjectId = this.objectId;
        const rootNode: NetworkNode = this.nodes.get(rootObjectId)!;
        const nodesToKeep: NetworkNode[] = [];
        const edgesToDelete: IdType[] = [];
        const nodesToDelete: IdType[] = [];

        nodesToKeep.push(rootNode);

        const edgeIds = this.edges.getIds();
        for (const edgeId of edgeIds) {
            const edge = this.edges.get(edgeId)!;
            if (edge.from === rootObjectId) {
                const nodeToKeep: NetworkNode | null = this.nodes.get(edge.to);
                if (nodeToKeep) nodesToKeep.push(nodeToKeep);
            } else {
                edgesToDelete.push(edgeId);
            }
        }

        const nodeIds = this.nodes.getIds();
        for (const nodeId of nodeIds) {
            const node: NetworkNode = this.nodes.get(nodeId)!;
            if (!nodesToKeep.includes(node)) {
                nodesToDelete.push(nodeId);
            }
        }

        this.removeNodes(nodesToDelete);
        this.removeEdges(edgesToDelete);
    }

    setNewTargetObject(objectIdParam: string): void {
        if (!this.nodes || !this.edges) return;
        this.nodes.clear();
        this.edges.clear();
        this.nodes = new DataSet();
        this.edges = new DataSet();
        delete this.network;
        this.canvasDiv.css("visibility", "hidden");

        this.objectId = objectIdParam;

        this.inboundToggleButton
            .children(":first-child")
            .empty()
            .append('<i class="fa-arrow-right"></i>Restart with inbound links');

        this.outboundOnly = true;

        this.existingEdges = {};
        this.existingNodes = {};
        const requestedLevel = 1;
        const zoomExtent = true;
        this.addReferrersLinkIfNeeded();
        APP.getRelationships(
            this.objectId,
            (response: RelationshipResponse) => {
                this.addNewRelationshipsToGraph(response, requestedLevel, zoomExtent);
            },
            (err: unknown) => this.onGotRelationshipsError(err),
            this.outboundOnly
        );
    }

    removeEdges(edgeIds: IdType[]): void {
        this.edges?.remove(edgeIds);
        for (const edgeId of edgeIds) {
            // delete edgeId.id;
            // delete edgeId.level; // level not part of edgeName
            // const edgeName = JSON.stringify(edgeId);
            delete this.existingEdges[edgeId];
        }
    }

    removeNodes(nodeIds: IdType[]): void {
        this.nodes?.remove(nodeIds);
        for (const nodeId of nodeIds) {
            if (nodeId === this.currentSelectedNode) delete this.currentSelectedNode;
            delete this.existingNodes[nodeId];
        }
    }

    setInitialPositionForNodes(nodes: NetworkNode[]): void {
        nodes[0].y = 200;
        nodes[0].allowedToMoveY = true;
        nodes[1].y = 600;
        nodes[1].allowedToMoveY = true;
    }

    addLabelsToEdges(edges: NetworkEdge[], searchResultsMap: Record<string, CordraObject>): void {
        for (const edge of edges) {
            const fromId = edge.from;
            const fromSearchResult = searchResultsMap[fromId];
            if (fromSearchResult != null) {
                this.addLabelToEdge(edge, fromSearchResult);
            }
        }
    }

    addLabelToEdge(edge: NetworkEdge, fromSearchResult: CordraObject): void {
        const jsonPointer = edge.jsonPointer;
        const schema: JsonSchema = APP.getSchema(fromSearchResult.type!);
        const pointerToSchemaMap = SchemaExtractorFactory.get().extract(
            fromSearchResult.content,
            schema
        );
        const subSchema = pointerToSchemaMap[jsonPointer];
        if (subSchema === undefined) return;
        const handleReferenceNode = SchemaUtil.getDeepCordraSchemaProperty(
            subSchema,
            "type",
            "handleReference"
        ) as {
            types: string[];
            prepend?: string;
            prependHandleMintingConfigPrefix?: string;
            name?: string;
        };
        if (!handleReferenceNode) return;
        const handleReferenceType = handleReferenceNode.types;
        if (!handleReferenceType) return;
        let idPointedToByReference = JsonUtil.getJsonAtPointer(
            fromSearchResult.content as object,
            jsonPointer
        );
        let handleReferencePrepend = handleReferenceNode.prepend;
        if (!handleReferencePrepend && handleReferenceNode.prependHandleMintingConfigPrefix) {
            const prefix = APP.getPrefix();
            if (prefix) handleReferencePrepend = this.ensureSlash(prefix);
        }
        if (handleReferencePrepend) {
            idPointedToByReference =
                handleReferencePrepend + idPointedToByReference;
        }
        if (idPointedToByReference !== edge.to) return;

        const handleReferenceName = handleReferenceNode.name;
        if (!handleReferenceName) {
            edge.label = jsonPointer;
        } else if (handleReferenceName.startsWith("{{") && handleReferenceName.endsWith("}}")) {
            const expression = handleReferenceName.substring(2, handleReferenceName.length - 4);
            const label = this.getValueForExpression(
                jsonPointer,
                expression,
                fromSearchResult.content as object
            );
            if (label && label !== "") {
                edge.label = label;
            }
        } else {
            edge.label = handleReferenceName;
        }
    }

    ensureSlash(prefix: string): string {
        if (prefix.length === 0) return "/";
        if (prefix.substring(prefix.length - 1) === "/") {
            return prefix;
        }
        return prefix + "/";
    }

    getValueForExpression(jsonPointer: string, expression: string, jsonObject: object): string | undefined {
        let result;
        const segments = jsonPointer.split("/").slice(1);
        if (expression.startsWith("/")) {
            // treat the expression as a jsonPointer starting at the root
            result = JsonUtil.getJsonAtPointer(jsonObject, expression);
        } else if (expression.startsWith("..")) {
            const segmentsFromRelativeExpression = expression.split("/").slice(1);
            segments.pop();
            const combinedSegments = segments.concat(segmentsFromRelativeExpression);
            const jsonPointerFromExpression = this.getJsonPointerFromSegments(combinedSegments);
            result = JsonUtil.getJsonAtPointer(
                jsonObject,
                jsonPointerFromExpression
            );
        } else {
            // consider the expression to be a jsonPointer starting at the current jsonPointer
            const targetPointer = jsonPointer + "/" + expression;
            result = JsonUtil.getJsonAtPointer(jsonObject, targetPointer);
        }

        if (result && typeof result !== "string") {
            result = JSON.stringify(result);
        }
        return result as string | undefined;
    }

    getJsonPointerFromSegments(segments: string[]): string {
        return segments
            .map(JsonUtil.encodeJsonPointerSegment)
            .join('/');
        // let jsonPointer = "";
        // for (const segment of segments) {
        //     const encodedSegment = JsonUtil.encodeJsonPointerSegment(segment);
        //     jsonPointer = jsonPointer + "/" + encodedSegment;
        // }
        // return jsonPointer;
    }

    addPreviewDataToNode(node: NetworkNode, searchResult: CordraObject): void {
        let nodeId = node.id;
        if (nodeId.length > 30) {
            nodeId = nodeId.substring(0, 30) + "...";
        }
        node.label = nodeId;

        const previewData = ObjectPreviewUtil.getPreviewData(searchResult);
        for (const jsonPointer in previewData) {
            const thisPreviewData = previewData[jsonPointer];
            if (thisPreviewData.isPrimary) {
                const prettifiedPreviewData = ObjectPreviewUtil.prettifyPreviewJson(
                    thisPreviewData.previewJson,
                    20
                );
                if (!prettifiedPreviewData) continue;
                node.label += `\n${thisPreviewData.title}: ${prettifiedPreviewData}`;
            }
        }

        // If there are multiple schemas in Cordra include the type
        if (APP.getSchemaCount() > 1) {
            const schema = APP.getSchema(searchResult.type!);
            let typeTitle = searchResult.type;
            if (schema && schema.title) {
                typeTitle = schema.title;
            }
            if (typeTitle) node.label += `\nType: ${typeTitle}`;
        }
    }

    addEdges(edgesToAdd: NetworkEdge[], requestedLevel: number): boolean {
        if (!this.edges) return false;
        let added = false;
        for (const edge of edgesToAdd) {
            const edgeName = JSON.stringify(edge);
            if (!this.existingEdges[edgeName]) {
                this.existingEdges[edgeName] = true;
                edge.level = requestedLevel;
                this.edges.add(edge);
                added = true;
            }
        }
        return added;
    }

    onFanOutAllClick(): void {
        if (!this.nodes || !this.edges) return;
        const requestedLevel = this.getCurrentTopLevel() + 1;
        for (const nodeId in this.existingNodes) {
            APP.getRelationships(
                nodeId,
                (response: RelationshipResponse) => {
                    this.addNewRelationshipsToGraph(response, requestedLevel);
                },
                (err: unknown) => this.onGotRelationshipsError(err),
                this.outboundOnly
            );
        }
    }

    onSelect(properties: { nodes: string[] }): void {
        const selectedNodes = properties.nodes;
        if (selectedNodes.length > 0) {
            this.currentSelectedNode = selectedNodes[0];
            this.displaySelectedNodeData(this.currentSelectedNode);
        } else {
            this.selectedDetails
                .empty()
                .append($('<ul class="graph-selected-node-preview"></ul>'));
        }
    }

    displaySelectedNodeData(selectedNodeId: string): void {
        const node = this.existingNodes[selectedNodeId];
        const searchResult = node.searchResult!;
        const previewData = ObjectPreviewUtil.getPreviewData(searchResult);
        const ul = $('<ul class="graph-selected-node-preview"></ul>');
        let placedId = false;
        for (const jsonPointer in previewData) {
            const thisPreviewData = previewData[jsonPointer];
            const prettifiedPreviewData = ObjectPreviewUtil.prettifyPreviewJson(
                thisPreviewData.previewJson
            );
            if (!prettifiedPreviewData) continue;
            let nodeDetails = $("<li/>");
            if (thisPreviewData.isPrimary) {
                const b = $("<b/>");
                nodeDetails.append(b);
                nodeDetails = b;
            }
            if (thisPreviewData.excludeTitle) {
                nodeDetails.text(prettifiedPreviewData);
            } else {
                nodeDetails.text(
                    thisPreviewData.title + ": " + prettifiedPreviewData
                );
            }
            if (thisPreviewData.isPrimary && !placedId) {
                ul.prepend($("<li/>").text("Id: " + searchResult.id));
                ul.prepend(nodeDetails);
                placedId = true;
            } else {
                ul.append(nodeDetails);
            }
        }
        if (!placedId) {
            ul.prepend($("<li/>").append($("<b/>").text("Id: " + searchResult.id)));
        }
        this.selectedDetails.empty().append(ul);
        this.onResize(false);
    }

    onFanOutSelectedClick(): void {
        if (!this.nodes || !this.edges) return;
        this.fanOutSelectedButton.trigger("blur");
        if (this.currentSelectedNode != null) {
            const requestedLevel = this.getCurrentTopLevel() + 1;
            APP.getRelationships(
                this.currentSelectedNode,
                (response: RelationshipResponse) => {
                    this.addNewRelationshipsToGraph(response, requestedLevel);
                },
                (err: unknown) => this.onGotRelationshipsError(err),
                this.outboundOnly
            );
        }
    }

    onResize(force: boolean = false): void {
        if (!this.network) return;
        if (this.isBig) {
            this.canvasDiv.height(
                this.containerDiv.height()! - this.selectedDetails.outerHeight(true)! - 52
            );
        }
        if (this.isBig || force) {
            this.network.setSize(this.canvasDiv.width()!.toString(), this.canvasDiv.height()!.toString());
            this.network.redraw();
        }
    }

    onGotRelationshipsError(res: unknown): void {
        console.log(res);
    }
}
