import { JsonSchema } from "tv4";
import { SchemaExtractorFactory } from "./SchemaExtractor";
import { SchemaUtil } from "./SchemaUtil";
import { JsonUtil } from "./JsonUtil";

export function clone(objectToClone: unknown, _type: string, schema: JsonSchema): unknown {
    const newObject = $.extend(true, {}, objectToClone);

    const pointerToSchemaMap = SchemaExtractorFactory.get().extract(objectToClone, schema);
    for (const jsonPointer in pointerToSchemaMap) {
        const subSchema = pointerToSchemaMap[jsonPointer];
        const autoGeneratedFieldNode = SchemaUtil.getDeepCordraSchemaProperty(subSchema, 'type', 'autoGeneratedField');
        if (!autoGeneratedFieldNode) continue;
        JsonUtil.replaceJsonAtPointer(newObject, jsonPointer, null);
    }

    //Remove any server set properties
    //Remove any payloads, file names.
    return newObject;
}
