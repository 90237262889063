import { CordraObject, SearchResults } from "@cnri/cordra-client";
import { ObjectPreviewUtil } from "../ObjectPreviewUtil";

export class HandleRefSearchSelector {
    private readonly types: string | string[];
    private readonly excludeTypes: string | string[];
    private readonly prepend: string = '';
    private readonly link: JQuery<HTMLLinkElement>;
    private readonly textInput: JQuery<HTMLInputElement> & { popover: (arg: unknown) => void };
    private readonly onPopoverItemChange: null | ((value: string) => void);
    private lastQuery = 0;
    private pendingQueryTimeoutId: number | undefined;
    private lastResults: SearchResults<CordraObject> | undefined;

    constructor(
            textInput: JQuery<HTMLInputElement> & { popover: (arg: unknown) => void },
            onPopoverItemChange: null | ((value: string) => void),
            typesParam: string | string[],
            excludeTypesParam: string | string[],
            prependParam?: string,
            prependHandleMintingConfigPrefix: boolean = false) {
        this.types = typesParam;
        this.excludeTypes = excludeTypesParam;
        this.link = $("<a></a>");
        this.onPopoverItemChange = onPopoverItemChange;
        if (!prependParam && prependHandleMintingConfigPrefix) {
            const prefix = APP.getPrefix();
            if (prefix) this.prepend = this.ensureSlash(prefix);
        }
        textInput.attr("placeholder", this.placeholderForTypes(this.types));
        textInput.on("keyup", () => this.onChange());
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const whiteList = $.fn.popover.Constructor.DEFAULTS.whiteList;
        whiteList.a = ['data-handle', 'target', 'href', 'title', 'rel' ];
        whiteList.div = ['data-handle' ];
        textInput.popover({
            content: () => this.popoverContent(),
            placement: "bottom",
            html: true,
            trigger: "manual",
            whiteList
        });
        textInput.on("shown.bs.popover", () => this.onShownPopover());
        textInput.on("blur", () => this.onBlur());
        textInput.after(this.link);
        textInput.on('change', () => this.getTargetObject());
        this.textInput = textInput;
    }

    ensureSlash(prefix: string): string {
        if (prefix.length === 0) return "/";
        if (prefix.substring(prefix.length - 1) === "/") {
            return prefix;
        }
        return prefix + "/";
    }

    popoverContent(): HTMLElement | string {
        if (this.lastResults == null) return "No results.";
        const outerDiv = $('<div class="container"></div>');
        const div = $('<div class="search-results-list col-md-12"></div>');
        outerDiv.append(div);
        const results = this.lastResults.results;
        if (results.length === 0) return "No results.";
        for (let i = 0; i < results.length; i++) {
            const id = results[i].id!;
            if (this.prepend && !id.startsWith(this.prepend)) continue;
            const resultsDiv = ObjectPreviewUtil.elementForSuggestion(results[i]);
            div.append(resultsDiv);
        }
        return outerDiv.html();
    }

    onShownPopover(): void {
        this.textInput
            .next(".popover")
            .find("a")
            .each((_, el) => {
                $(el).on("click", (e) => {
                    this.onPopoverItemClick(e, $(el));
                });
            });
        this.textInput
            .next(".popover")
            .find(".search-result")
            .each((_, el) => {
                $(el).on("click", (e) => {
                    this.onPopoverItemClick(e, $(el));
                });
            });
    }

    onPopoverItemClick(e: JQuery.ClickEvent, element: JQuery): void {
        e.preventDefault();
        e.stopPropagation();
        this.textInput.val("");
        let newValue = element.attr("data-handle") as string;
        if (this.prepend && newValue.startsWith(this.prepend)) {
            newValue = newValue.substring(this.prepend.length);
        }
        if (this.onPopoverItemChange) {
            this.onPopoverItemChange(newValue);
        }
        this.textInput.val(newValue);
        this.textInput.popover("hide");
        this.getTargetObject();
    }

    getTargetObject(): void {
        this.link.text("");
        this.link.attr("href", "#");
        const targetObjectId = this.prepend + this.textInput.val();
        if (targetObjectId) {
            this.resolveHandle(targetObjectId);
        }
    }

    resolveHandle(targetObjectId: string): void {
        APP.getObject(
            targetObjectId,
            (obj: CordraObject) => this.onGotTargetObject(obj),
            (err: unknown) => this.onGotTargetObjectError(err)
        );
    }

    onGotTargetObjectError(res: unknown): void {
        console.log(res);
    }

    onGotTargetObject(obj: CordraObject): void {
        this.renderTargetObjectPreview(obj);
    }

    renderTargetObjectPreview(targetObject: CordraObject): void {
        const previewData = ObjectPreviewUtil.getPreviewData(targetObject);
        let linkText = targetObject.id!;
        for (const jsonPointer in previewData) {
            const previewDataItem = previewData[jsonPointer];
            if (previewDataItem.isPrimary) {
                linkText = previewDataItem.title + ": " + previewDataItem.previewJson;
            }
        }
        this.link.text(linkText);
        this.link.attr("href", "#objects/" + targetObject.id!);
    }

    onBlur(): void {
        this.textInput.popover("hide");
    }

    onChange(): void {
        const now = Date.now();
        if (now - this.lastQuery >= 500) {
            this.doQuery();
        } else {
            if (this.pendingQueryTimeoutId) clearTimeout(this.pendingQueryTimeoutId);
            this.pendingQueryTimeoutId = window.setTimeout(() => this.doQuery(), 500 - (now - this.lastQuery));
        }
    }

    doQuery(): void {
        const text = this.textInput.val();
        if (text === "") {
            return;
        }
        let query = "";
        if (this.types) {
            if (typeof this.types === "string" || this.types.length > 0) {
                query = this.queryForTypes(this.types) + " ";
            }
        }
        if (this.excludeTypes) {
            if (typeof this.excludeTypes === "string" || this.excludeTypes.length > 0) {
                query += "-" + this.queryForTypes(this.excludeTypes) + " ";
            }
        }
        query += "+internal.all:(" + text + ")";
        APP.search(
            query,
            0,
            10,
            undefined,
            (resp: SearchResults<string | CordraObject>) => this.onSuccess(resp),
            (e: unknown) => this.onError(e));

        this.lastQuery = Date.now();
        this.pendingQueryTimeoutId = undefined;
    }

    onSuccess(response: SearchResults<string | CordraObject>): void {
        this.lastResults = response as SearchResults<CordraObject>;
        this.textInput.popover("show");
    }

    onError(response: unknown): void {
        console.error(response);
    }

    placeholderForTypes(types: string | string[]): string {
        if (this.excludeTypes) {
            if (typeof this.excludeTypes === "string") {
                return "Any type except " + this.excludeTypes;
            } else if (this.excludeTypes.length > 0) {
                let res = "Any type except ";
                res += this.excludeTypes.join(', ');
                return res;
            }
        }
        if (!types) {
            return "Any type";
        }
        if (typeof types === "string") {
            return types;
        } else {
            if (types.length === 0) {
                return "Any type";
            }
            return types.join(', ');
        }
    }

    queryForTypes(types: string | string[]): string {
        if (typeof types === "string") {
            return "+(type:" + types + ")";
        }
        if (types.length === 0) {
            return "";
        }
        let query = "+(";
        query += types.map(t => `type:"${t}"`).join(' ');
        query += ")";
        return query;
    }
}
