import { default as JsonEditorOnline, JSONEditorOptions } from 'jsoneditor';
import { ModalYesNoDialog } from "../cordra/ModalYesNoDialog";
import { UpdateAllHandlesStatusWidget } from "./UpdateAllHandlesStatusWidget";

export interface HandleMintingConfig {
    handleAdminIdentity?: string;
    prefix?: string;
    baseUri?: string;
    javascript?: string;
    javascriptIsModule?: boolean;
}

export class HandleMintingConfigEditor {
    private readonly haiInput: JQuery<HTMLInputElement>;
    private readonly prefixInput: JQuery<HTMLInputElement>;
    private readonly baseUriInput: JQuery<HTMLInputElement>;
    private readonly jimSelect: JQuery<HTMLSelectElement>;
    private readonly editor: JsonEditorOnline;
    private readonly editorDiv: JQuery<HTMLDivElement>;
    private readonly saveButton: JQuery<HTMLButtonElement>;

    private readonly scriptEditorDiv: JQuery<HTMLDivElement>;
    private readonly scriptEditor: AceAjax.Editor;

    private readonly form: JQuery<HTMLFormElement>;
    private isAdvanced = false;

    private readonly updateWell: JQuery<HTMLDivElement>;
    private readonly updateProgressDiv: JQuery<HTMLDivElement>;
    private readonly updateProgressWidget: UpdateAllHandlesStatusWidget;
    private readonly toolBarDiv: JQuery<HTMLDivElement>;
    private readonly updateToolBarDiv: JQuery<HTMLDivElement>;

    constructor(containerDiv: JQuery, handleMintingConfig: HandleMintingConfig, disabled: boolean) {
        const headerRow = $('<div class="row object-header"></div>');
        containerDiv.append(headerRow);

        const objectHeader = $('<div class="heading col-md-6"></div>');
        const objectIdHeading = $('<h3 class="editorTitle">Handle Records</h3>');
        objectHeader.append(objectIdHeading);
        headerRow.append(objectHeader);

        this.toolBarDiv = $(
            '<div class="object-editor-toolbar col-md-6 pull-right"></div>'
        );
        headerRow.append(this.toolBarDiv);

        if (disabled) {
            this.saveButton = $(
                '<button class="btn btn-sm btn-primary" style="display:none"><i class="fa fa-save"></i></button>'
            );
        } else {
            this.saveButton = $(
                '<button class="btn btn-sm btn-primary"><i class="fa fa-save"></i></button>'
            );
        }
        this.toolBarDiv.append(this.saveButton);
        this.saveButton.on("click", () => this.save());

        const saveButtonSpan = $("<span></span>");
        this.saveButton.append(saveButtonSpan);
        saveButtonSpan.text("Save");

        const viewsDropDownButtonGroup = $('<div class="btn-group"></div>');
        this.toolBarDiv.append(viewsDropDownButtonGroup);

        const viewsDropDownButton = $(
            '<button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-toggle="dropdown"><i class="fa fa-eye"></i>Views...<span class="caret"></span></button>'
        );
        viewsDropDownButtonGroup.append(viewsDropDownButton);

        const viewDropDownMenu = $('<ul class="dropdown-menu"></ul>');
        viewsDropDownButtonGroup.append(viewDropDownMenu);

        const basicToggleButton = $(
            '<li><a class="dropdown-item"><i class="fa fa-cube"></i>Default</a></li>'
        );
        viewDropDownMenu.append(basicToggleButton);
        basicToggleButton.on("click", () => this.showBasic());

        const advancedToggleButton = $(
            '<li><a class="dropdown-item"><i class="fa fa-stream"></i>JSON</a></li>'
        );
        viewDropDownMenu.append(advancedToggleButton);
        advancedToggleButton.on("click", () => this.showAdvanced());

        let description = $(
            "<p>This structure lets you configure Cordra to create handle records for each new object created. " +
            "Note: if you enable handle resolution using cordra-client-handle-storage at your handle server, " +
            "you should not also configure Cordra to create handle records.  You may still want to set the " +
            "prefix value, which determines which prefix Cordra will use by default for new objects.</p>"
        );
        containerDiv.append(description);
        description = $(
            "<p>To have Cordra create handle records, you must configure either a base URI (ending with a slash) " +
            "or JavaScript, as described in the Cordra technical manual.  If using a base URI (the common case), " +
            "the handle records consist of a value redirecting handle resolvers to this Cordra's API and/or user interface. " +
            "Specify the base URI (ending with a slash) where Cordra is accessible in the Internet for this configuration to take effect.</p>"
        );
        containerDiv.append(description);
        description = $(
            "<p>Note: If creating handle values with JavaScript it is important to consider that all cordra objects, " +
            "regardless of ACL, will have a handle record created. " +
            "If you are storing data directly in the handle record you may wish to check if the Cordra object is publicly accessible. " +
            "You can do this by inspecting the 'context' argument.</p>"
        );
        containerDiv.append(description);

        this.form = $('<form class="form-horizontal col-md-12"></form>');
        containerDiv.append(this.form);

        const group = $('<div class="form-group"></div>');
        this.form.append(group);

        const prefixLabel = $(
            '<label for="prefixInput" class="col-sm-2 control-label">Prefix</label>'
        );
        group.append(prefixLabel);
        const prefixDiv = $('<div class="col-sm-10"></div>');
        group.append(prefixDiv);
        this.prefixInput = $(
            '<input class="form-control" id="prefixInput" placeholder="Prefix"/>'
        );
        prefixDiv.append(this.prefixInput);
        if (handleMintingConfig.prefix) this.prefixInput.val(handleMintingConfig.prefix);

        const haiLabel = $(
            '<label for="haiInput" class="col-sm-2 control-label">Handle Admin Identity</label>'
        );
        group.append(haiLabel);
        const haiDiv = $('<div class="col-sm-10"></div>');
        group.append(haiDiv);
        this.haiInput = $(
            '<input class="form-control" id="haiInput" placeholder="Handle Admin Identity"/>'
        );
        haiDiv.append(this.haiInput);
        if (handleMintingConfig.handleAdminIdentity) this.haiInput.val(handleMintingConfig.handleAdminIdentity);

        const label = $(
            '<label for="baseUriInput" class="col-sm-2 control-label">Base URI</label>'
        );
        group.append(label);

        const div = $('<div class="col-sm-10"></div>');
        group.append(div);

        this.baseUriInput = $(
            '<input class="form-control" id="baseUriInput" placeholder="Base URI"/>'
        );
        div.append(this.baseUriInput);
        if (handleMintingConfig.baseUri) this.baseUriInput.val(handleMintingConfig.baseUri);

        const jimLabel = $('<label class="col-sm-2 control-label">JavaScript is module</label>');
        group.append(jimLabel);

        const jimDiv = $('<div class="col-sm-10"/>');
        group.append(jimDiv);
        this.jimSelect = $('<select class="form-control"><option value=""> </option><option value="true">true</option><option value="false">false</option></select>');
        if (handleMintingConfig.javascriptIsModule !== undefined) {
            this.jimSelect.val(handleMintingConfig.javascriptIsModule.toString());
        }
        jimDiv.append(this.jimSelect);

        const jsLabel = $('<label class="col-sm-2 control-label">JavaScript</label>');
        group.append(jsLabel);

        const scriptEditorDivContainer = $('<div class="col-sm-10"/>');
        this.haiInput.css("margin-bottom", "10px");
        this.prefixInput.css("margin-bottom", "10px");
        this.baseUriInput.css("margin-bottom", "10px");
        this.jimSelect.css("margin-bottom", "10px");
        this.scriptEditorDiv = $(
            '<div id="handleMintingJavaScriptEditor" class="ace_editor"></div>'
        );
        scriptEditorDivContainer.append(this.scriptEditorDiv);
        group.append(scriptEditorDivContainer);

        this.scriptEditor = ace.edit("handleMintingJavaScriptEditor");
        this.scriptEditor.setTheme("ace/theme/textmate");
        APP.fixAceJavascriptEditor(this.scriptEditor);
        this.scriptEditor.getSession().setMode("ace/mode/javascript");
        this.scriptEditor.setOptions({
            maxLines: Infinity,
            minLines: 10
        });
        this.scriptEditor.$blockScrolling = Infinity;
        if (handleMintingConfig.javascript) {
            this.scriptEditor.setValue(handleMintingConfig.javascript, -1);
        }

        this.editorDiv = $('<div style="height:500px; display:none; col-md-12"></div>');
        containerDiv.append(this.editorDiv);

        const container = this.editorDiv[0];
        const options = {
            ace,
            theme: "ace/theme/textmate",
            mode: "code",
            modes: ["code", "tree"], // allowed modes
            onError(err: Error) {
                alert(err.toString());
            }
        } as JSONEditorOptions;
        this.editor = new JsonEditorOnline(container, options, handleMintingConfig);
        if (disabled) {
            APP.disableJsonEditorOnline(this.editor);
        }

        this.updateWell = $('<div class="col-md-12" style="margin-top: 50px"></div>');
        containerDiv.append(this.updateWell);

        if (disabled) {
            this.updateWell.hide();
        }
        const headerRow2 = $('<div class="row object-header"></div>');
        this.updateWell.append(headerRow2);

        const objectHeader2 = $('<div class="heading col-md-6"></div>');
        const objectIdHeading2 = $(
            '<h3 class="editorTitle">Update Handles Records </h3>'
        );
        objectHeader2.append(objectIdHeading2);
        headerRow2.append(objectHeader2);

        this.updateToolBarDiv = $(
            '<div class="object-editor-toolbar col-md-6 pull-right"></div>'
        );
        headerRow2.append(this.updateToolBarDiv);

        const updateHandlesButton = $(
            '<button class="btn btn-sm btn-primary"><i class="fa fa-pen"></i></button>'
        );
        this.updateToolBarDiv.append(updateHandlesButton);
        updateHandlesButton.on("click", () => this.updateHandles());

        const updateHandlesButtonSpan = $("<span></span>");
        updateHandlesButton.append(updateHandlesButtonSpan);
        updateHandlesButtonSpan.text("Update All Handles");

        this.updateProgressDiv = $('<div style="display:none"></div>');
        this.updateWell.append(this.updateProgressDiv);

        this.updateProgressWidget = new UpdateAllHandlesStatusWidget(this.updateProgressDiv);

        const updateDescription = $(
            "<p>Once the configuration above is updated, click on the Update All Handles button to update all existing handle records pertaining to this Cordra instance according to the above configuration.</p>"
        );
        this.updateWell.append(updateDescription);

        if (!disabled) {
            this.pollUpdateStatus();
        }
    }

    onCloseClick(): void {
        APP.clearFragment();
    }

    enable(): void {
        this.saveButton.show();
        APP.enableJsonEditorOnline(this.editor);
        this.updateWell.show();
        this.pollUpdateStatus();
    }

    disable(): void {
        this.saveButton.hide();
        this.updateWell.hide();
        APP.disableJsonEditorOnline(this.editor);
    }

    destroy(): void {
        this.editor.destroy();
        this.scriptEditor.destroy();
    }

    showAdvanced(): void {
        this.setEditorValueBasedOnBasic();
        this.form.hide();
        this.editorDiv.show();
        this.isAdvanced = true;
    }

    showBasic(): void {
        const handleMintingConfigUpdate = this.editor.get() as HandleMintingConfig;
        if (handleMintingConfigUpdate.handleAdminIdentity) this.haiInput.val(handleMintingConfigUpdate.handleAdminIdentity);
        if (handleMintingConfigUpdate.prefix) this.prefixInput.val(handleMintingConfigUpdate.prefix);
        if (handleMintingConfigUpdate.baseUri) this.baseUriInput.val(handleMintingConfigUpdate.baseUri);
        if (handleMintingConfigUpdate.javascriptIsModule !== undefined) {
            this.jimSelect.val(handleMintingConfigUpdate.javascriptIsModule.toString());
        } else {
            this.jimSelect.val("");
        }
        if (handleMintingConfigUpdate.javascript) {
            this.scriptEditor.setValue(handleMintingConfigUpdate.javascript, -1);
        } else {
            this.scriptEditor.setValue("", -1);
        }
        this.form.show();
        this.editorDiv.hide();
        this.isAdvanced = false;
    }

    setEditorValueBasedOnBasic(): void {
        const handleMintingConfigUpdate = this.editor.get() as HandleMintingConfig;
        const newHai = this.haiInput.val() as string;
        if (newHai) {
            handleMintingConfigUpdate.handleAdminIdentity = newHai;
        } else {
            delete handleMintingConfigUpdate.handleAdminIdentity;
        }
        const newPrefix = this.prefixInput.val() as string;
        if (newPrefix) {
            handleMintingConfigUpdate.prefix = newPrefix;
        } else {
            delete handleMintingConfigUpdate.prefix;
        }
        const newBaseUri = this.baseUriInput.val() as string;
        if (newBaseUri) {
            handleMintingConfigUpdate.baseUri = newBaseUri;
        } else {
            delete handleMintingConfigUpdate.baseUri;
        }
        const javascriptIsModule = this.jimSelect.val();
        if (javascriptIsModule !== "") {
            handleMintingConfigUpdate.javascriptIsModule = (javascriptIsModule === "true");
        } else {
            delete handleMintingConfigUpdate.javascriptIsModule;
        }
        const js = this.scriptEditor.getValue();
        if (js) {
            handleMintingConfigUpdate.javascript = js;
        } else {
            delete handleMintingConfigUpdate.javascript;
        }
        this.editor.set(handleMintingConfigUpdate);
    }

    save(): void {
        if (!this.isAdvanced) {
            this.setEditorValueBasedOnBasic();
        }
        APP.saveHandleMintingConfig(this.editor.get());
    }

    updateHandles(): void {
        const dialog = new ModalYesNoDialog(
            "Updating all handles can take a long time. Are you sure you want to start this process?",
            () => this.yesUpdateHandles(),
            () => this.noUpdateHandles()
        );
        dialog.show();
    }

    yesUpdateHandles(): void {
        this.save();
        this.updateProgressWidget.clear();
        APP.updateAllHandles(() => this.pollUpdateStatus());
    }

    pollUpdateStatus(): void {
        APP.getHandleUpdateStatus((status: { progress: number; total: number; inProgress: boolean; exceptionCount: number }) => {
            if (!status.inProgress && status.total === 0) return;
            this.updateProgressWidget.setStatus(status);
            this.updateProgressDiv.show();
            if (status.inProgress) {
                setTimeout(() => this.pollUpdateStatus(), 100);
            } else {
                //complete
            }
        });
    }

    noUpdateHandles(): void {
        //no-op
    }
}
