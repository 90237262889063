import { CordraObject } from "@cnri/cordra-client";
import { JsonSchema } from "tv4";
import { SchemaExtractorFactory } from "./cordra/SchemaExtractor";
import { SchemaUtil } from "./cordra/SchemaUtil";
import { JsonUtil } from "./cordra/JsonUtil";

export const ObjectPreviewUtil = {
    elementForSuggestion,
    prettifyPreviewJson,
    getPreviewData
};

export interface PreviewData {
    title: string;
    previewJson: unknown;
    showInPreview?: boolean;
    isUri?: boolean;
    isPrimary?: boolean;
    excludeTitle?: boolean;
}

function elementForSuggestion(searchResult: CordraObject): JQuery {
    const handleString = searchResult.id!;
    const searchResultDiv = $('<div class="search-result col-md-12"/>');
    searchResultDiv.attr("data-handle", handleString);
    const previewData = getPreviewData(searchResult);
    const headerRow = $('<div class="header row"/>');
    searchResultDiv.append(headerRow);
    const header = $('<h4 class="col-md-12"></h4>');
    headerRow.append(header);
    let link = $('<a class="list-handles-link" target="_blank">')
        .attr("href", "#objects/" + handleString)
        .text(handleString);
    link.attr("data-handle", handleString);

    for (const jsonPointer in previewData) {
        const thisPreviewData = previewData[jsonPointer];
        const prettifiedPreviewData = prettifyPreviewJson(
            thisPreviewData.previewJson
        );
        if (!prettifiedPreviewData) continue;

        if (thisPreviewData.isPrimary) {
            link = $('<a class="list-handles-link" target="_blank">')
                .attr("href", "#objects/" + handleString)
                .text(prettifiedPreviewData);
            link.attr("data-handle", handleString);
        }
    }
    header.prepend(link);
    const objectIdSpan = $(
        '<div class="header-id col-md-12" title="Object ID" data-original-title="ObjectID"></div>'
    );
    const objectIdLink = $('<a class="list-handles-link" target="_blank">')
        .attr("href", "#objects/" + handleString)
        .text(handleString);
    objectIdLink.attr("data-handle", handleString);
    objectIdSpan.append(objectIdLink);

    headerRow.append(objectIdSpan);
    return searchResultDiv;
}

function prettifyPreviewJson(previewJson: unknown, maxLength?: number): string {
    let result;
    if (typeof previewJson === "string") {
        result = previewJson;
    } else {
        result = JSON.stringify(previewJson);
    }
    if (maxLength) {
        if (result.length > maxLength) {
            result = result.substring(0, maxLength) + "...";
        }
    }
    return result;
}

function getPreviewData(searchResult: CordraObject): Record<string, PreviewData> {
    const res: Record<string, PreviewData> = {};
    const schema = APP.getSchema(searchResult.type!);
    if (!schema) return res;
    const content = searchResult.content;
    const pointerToSchemaMap = SchemaExtractorFactory.get().extract(
        content,
        schema
    );
    let foundPrimary = false;
    for (const jsonPointer in pointerToSchemaMap) {
        const subSchema = pointerToSchemaMap[jsonPointer];
        const previewNode = SchemaUtil.getDeepCordraSchemaProperty(
            subSchema,
            "preview"
        ) as Record<string, boolean>;
        if (!previewNode) continue;
        const showInPreview = previewNode.showInPreview;
        const isPrimary = previewNode.isPrimary;
        const excludeTitle = previewNode.excludeTitle;
        if (!showInPreview) continue;
        let title = subSchema.title;
        if (!title) title = jsonPointer;
        const previewJson = JsonUtil.getJsonAtPointer(content as JsonSchema, jsonPointer);
        const data: PreviewData = { title, previewJson, showInPreview };
        if (subSchema.format === "uri") {
            data.isUri = true;
        }
        if (isPrimary && !foundPrimary) {
            data.isPrimary = true;
            foundPrimary = true;
        }
        if (excludeTitle) {
            data.excludeTitle = true;
        }
        res[jsonPointer] = data;
    }
    return res;
}
