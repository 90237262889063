export class SearchResultsPagination {
    private readonly showTotalResultsLabel: boolean = true;

    constructor(
            containerDiv: JQuery<HTMLDivElement>,
            numResults: number,
            pageOffSet: number,
            pageSize: number,
            onPageClick: (e: JQuery.ClickEvent) => void
    ) {
        const paginationContainer = $('<div class="pagination-container"></div>');
        containerDiv.append(paginationContainer);

        const nav = $('<ul class="pagination cordra-pagination-small">');
        nav.css("vertical-align", "middle");
        nav.css("margin-bottom", "0px;");
        paginationContainer.append(nav);

        const totalNumOfPages = numResults === -1 ? -1 : numResults / pageSize;
        const roundedTotalNumOfPages = Math.ceil(totalNumOfPages);
        const currentPageNumber = pageOffSet;
        const startingNumber = this.getStartPaginationNumberForCurrent(
            currentPageNumber,
            totalNumOfPages
        );
        const endingNumber = this.getEndPaginationNumberForCurrent(
            currentPageNumber,
            roundedTotalNumOfPages
        );

        if (totalNumOfPages > 1 || totalNumOfPages === -1) {
            const prevLi = $('<li class="page-item">');
            const prevPage = $('<a id="pagePrev" href="#" class="page-link">«</a>');
            if (currentPageNumber === 0) {
                prevLi.addClass("disabled");
                prevPage.data("pageNumber", currentPageNumber);
            } else {
                prevPage.data("pageNumber", currentPageNumber - 1);
            }
            prevPage.on("click", onPageClick);
            prevLi.append(prevPage);
            nav.append(prevLi);

            for (let i = startingNumber; i < endingNumber; i++) {
                const pageNumForDisplay = i + 1;
                const li = $('<li class="page-item">');
                const page = $('<a href="#" class="page-link"></a>')
                    .attr("id", "page" + pageNumForDisplay)
                    .text(pageNumForDisplay);
                if (currentPageNumber === i) {
                    li.addClass("active");
                }
                page.data("pageNumber", i);
                page.on("click", onPageClick);
                li.append(page);
                nav.append(li);
            }

            const nextLi = $('<li class="page-item">');
            const nextPage = $('<a id="pageNext" href="#" class="page-link">»</a>');
            if (currentPageNumber === roundedTotalNumOfPages - 1) {
                nextLi.addClass("disabled");
                nextPage.data("pageNumber", currentPageNumber);
            } else {
                nextPage.data("pageNumber", currentPageNumber + 1);
            }
            nextPage.on("click", onPageClick);
            nextLi.append(nextPage);
            nav.append(nextLi);
        }

        if (this.showTotalResultsLabel) {
            paginationContainer.append(" ");
            let totalLabel;
            const rangeText = this.getRangeTextForPage(pageSize, pageOffSet, numResults);
            if (numResults === 1) {
                totalLabel = $(
                    '<span class="pagination-text">Showing ' +
                    numResults +
                    " result </span>"
                );
            } else if (numResults === -1) {
                totalLabel = $(
                    '<span class="pagination-text">Showing ' +
                    rangeText +
                    " of many results</span>"
                );
            } else {
                totalLabel = $(
                    '<span class="pagination-text">Showing ' +
                    rangeText +
                    " of " +
                    numResults +
                    " results </span>"
                );
            }
            if (totalLabel) {
                totalLabel.css("vertical-align", "middle");
                paginationContainer.append(totalLabel);
            }
        }
    }

    getRangeTextForPage(pageSize: number, pageOffSet: number, numResults: number): string {
        const firstResultOnPageNumber = pageOffSet * pageSize + 1;
        let lastResultOnPageNumber = (pageOffSet + 1) * pageSize;
        if (numResults !== -1 && lastResultOnPageNumber > numResults) lastResultOnPageNumber = numResults;
        return firstResultOnPageNumber + " to " + lastResultOnPageNumber;
    }

    getStartPaginationNumberForCurrent(currentPageNumber: number, totalNumOfPages: number): number {
        const roundedTotalNumOfPages = Math.ceil(totalNumOfPages);
        let result;
        if (currentPageNumber < 6) {
            result = 0;
        } else {
            result = currentPageNumber - 5;
        }
        if (roundedTotalNumOfPages - result < 10) {
            result = roundedTotalNumOfPages - 10;
        }
        if (result < 0) {
            result = 0;
        }
        return result;
    }

    getEndPaginationNumberForCurrent(currentPageNumber: number, totalNumOfPages: number): number {
        let result;
        if (currentPageNumber >= 6) {
            result = currentPageNumber + 4;
        } else {
            result = 9;
        }
        if (result > totalNumOfPages) {
            result = totalNumOfPages;
        }
        return result;
    }
}
