/* eslint-disable */
// @ts-nocheck
import * as JSONEditor from '@json-editor/json-editor';
import { default as JsonEditorOnline, JSONEditorOptions } from 'jsoneditor';
import { SchemaUtil } from "./SchemaUtil";
import { JsonUtil } from "./JsonUtil";
import { UriEditor } from "./UriEditor";
import { HandleRefSearchSelector } from "./HandleRefSearchSelector";
import { SuggestedVocabularySelector } from "./SuggestedVocabularySelector";
import { AutoGeneratedField } from "./AutoGeneratedField";

JSONEditor.prototype._loadExternalRefs = function (schema, callback, fileBase) {
    // no-op
    callback();
};

JSONEditor.prototype.expandRefs = function (schema) {
    schema = $.extend({}, schema);
    while (schema.$ref) {
        const ref = schema.$ref;
        delete schema.$ref;
        const resolvedNormalizedSchema = APP.resolveNormalizedSchema(ref);
        schema = this.extendSchemas(schema, resolvedNormalizedSchema);
    }
    return schema;
};

// mods for required; put these first so we can inherit from them

function extendAndReplaceEditorForRequiredStarsAndDisableOption(editor) {
    const oldEditor = JSONEditor.defaults.editors[editor];
    JSONEditor.defaults.editors[editor] = oldEditor.extend({
        init(options) {
            this._super(options);
            this.addStar = false;
            if (this.parent) {
                let actualParent = this.parent;
                while (actualParent.path === this.path) {
                    if (actualParent.parent) actualParent = actualParent.parent;
                    else break;
                }
                this.addStar = actualParent.schema.type === 'array' || ($.isArray(actualParent.schema.required) && actualParent.schema.required.indexOf(this.key) > -1);
            }
        },
        build() {
            this._super();
            const editor = this;
            const inputEl = $(this.input);
            if (isDisableInUi(editor.schema)) {
                inputEl.prop("disabled", true);
            }
        },
        getHeaderText() {
            const res = this._super();
            if (this.addStar && res.charAt(res.length - 1) !== '*') return res + " *";
            else return res;
        },
        getTitle() {
            const res = this._super();
            if (this.addStar && res.charAt(res.length - 1) !== '*') return res + " *";
            else return res;
        }
    });
    JSONEditor.defaults.editors[editor].options = oldEditor.options;
}
extendAndReplaceEditorForRequiredStarsAndDisableOption("string");
extendAndReplaceEditorForRequiredStarsAndDisableOption("number");
extendAndReplaceEditorForRequiredStarsAndDisableOption("integer");
extendAndReplaceEditorForRequiredStarsAndDisableOption("select");

// make non-required enums undefined by default
const oldSelectEditor = JSONEditor.defaults.editors.select;
JSONEditor.defaults.editors.select = oldSelectEditor.extend({
    getDefault() {
        if (!this.isRequired()) return "";
        else return this._super();
    },
    preBuild() {
        this._super();
        // fix enum_options
        if (!this.isRequired() && (this.schema["enum"] || this.schema.type === "boolean")) {
            this.enum_options[0] = "";
            this.enum_values[0] = "";
        }
    }
});
JSONEditor.defaults.editors.select.options = oldSelectEditor.options;

const oldTableEditor = JSONEditor.defaults.editors.table;
JSONEditor.defaults.editors.table = oldTableEditor.extend({
    preBuild() {
        this._super();
        this.item_title = this.item_title + " *";
    },
    getItemTitle() {
        const len = this.item_title.length;
        if (len < 2 || this.item_title.charAt(len - 2) !== ' ' || this.item_title.charAt(len - 1) !== '*') return this.item_title;
        return this.item_title.substring(0, this.item_title.length - 2);
    },
    addRow(value) {
        this._super(value);
        if (!value && value !== undefined) this.rows[this.rows.length - 1].setValue(value);
    }
});
JSONEditor.defaults.editors.table.options = oldTableEditor.options;

const oldArrayEditor = JSONEditor.defaults.editors.array;
JSONEditor.defaults.editors.array = oldArrayEditor.extend({
    addRow(value, initial) {
        this._super(value, initial);
        if (!value && value !== undefined) this.rows[this.rows.length - 1].setValue(value, initial);
    }
});
JSONEditor.defaults.editors.array.options = oldArrayEditor.options;

// Fix up objects by removing non-required properties which would be empty
const oldObjectEditor = JSONEditor.defaults.editors.object;
JSONEditor.defaults.editors.object = oldObjectEditor.extend({
    isRequired(editor) {
        if (!this.jsoneditor.options.disabled) {
            // treat everything as required so that all fields show up in the UI
            return true;
        } else {
            return this._super(editor);
        }
    },
    setValue(value, initial) {
        this.originalValue = value;
        this._super(value, initial);
    },
    refreshValue() {
        this._super();
        const required = this.schema.required;
        const hasRequired = $.isArray(required);
        for (const i in this.editors) {
            if (!this.editors.hasOwnProperty(i)) continue;
            if (hasRequired && required.indexOf(i) > -1) continue;
            if (isAutoGenerated(this.editors[i].schema)) continue;
            const val = this.value[i];
            const origVal = this.originalValue && this.originalValue[i];
            if (!val) {
                if (origVal === val) continue;
                delete this.value[i];
            } else if ($.isPlainObject(val) && $.isEmptyObject(val)) {
                if ($.isPlainObject(origVal) && $.isEmptyObject(origVal)) continue;
                delete this.value[i];
            } else if ($.isArray(val) && val.length === 0) {
                if ($.isArray(origVal) && origVal.length === 0) continue;
                delete this.value[i];
            }
        }
    },
    getHeaderText() {
        // fix up templating for missing properties
        if (this.header_text) this.header_text = this.header_text.replace(/{{.*}}/g, '');
        return this._super();
    },
    addObjectProperty: function(name, prebuild_only) {
        // This entire method is copied from upstream v1.3.5, which a bugfix for broken patternProperties
        // See https://github.com/json-editor/json-editor/commit/2224fc99ebf15187d05454643d05bc213ed38904
        var self = this;

        // Property is already added
        if(this.editors[name]) return;

        // Property was added before and is cached
        if(this.cached_editors[name]) {
            this.editors[name] = this.cached_editors[name];
            if(prebuild_only) return;
            this.editors[name].register();
        }
        // New property
        else {
            // CNRI: This next line is the bugfix for COR-528
            if (!this.canHaveAdditionalProperties() && (!this.schema.properties || !this.schema.properties[name]) &&
                (!this.schema.patternProperties || !(Object.keys(this.schema.patternProperties).find(i => new RegExp(i).test(name))))) {
                return;
            }

            var schema = self.getPropertySchema(name);
            if(typeof schema.propertyOrder !== 'number'){
                // if the propertyOrder undefined, then set a smart default value.
                schema.propertyOrder = Object.keys(self.editors).length + 1000;
            }


            // Add the property
            var editor = self.jsoneditor.getEditorClass(schema);

            self.editors[name] = self.jsoneditor.createEditor(editor,{
                jsoneditor: self.jsoneditor,
                schema: schema,
                path: self.path+'.'+name,
                parent: self
            });
            self.editors[name].preBuild();

            if(!prebuild_only) {
                var holder = self.theme.getChildEditorHolder();
                self.editor_holder.appendChild(holder);
                self.editors[name].setContainer(holder);
                self.editors[name].build();
                self.editors[name].postBuild();
            }

            self.cached_editors[name] = self.editors[name];
        }

        // If we're only prebuilding the editors, don't refresh values
        if(!prebuild_only) {
            self.refreshValue();
            self.layoutEditors();
        }
    }
});
JSONEditor.defaults.editors.object.options = oldObjectEditor.options;

function isAutoGenerated(schema) {
    return SchemaUtil.getDeepCordraSchemaProperty(schema, 'type', 'autoGeneratedField');
}

function isDisableInUi(schema) {
    const fieldNode = SchemaUtil.getDeepCordraSchemaProperty(schema, 'disableInUi');
    if (!fieldNode) return false;
    return fieldNode;
}


// other mods

JSONEditor.defaults.editors.uriFormat = JSONEditor.defaults.editors.string.extend({
    build() {
        this._super();
        const editor = this;
        const textInput = $(this.input);
        this.uriEditor = new UriEditor(textInput, editor);
    },
    enable() {
        this.uriEditor.enable();
        this._super();
    },
    disable() {
        this.uriEditor.disable();
        this._super();
    }
});

JSONEditor.defaults.resolvers.unshift((schema) => {
    if (schema.format === "uri") {
        return "uriFormat";
    }
});

JSONEditor.defaults.editors.handleReferenceType = JSONEditor.defaults.editors.string.extend({
    build() {
        this._super();
        const editor = this;
        const textInput = $(this.input);
        const onPopoverItemChange = (newValue) => {
            editor.setValue(newValue);
        };
        const types = SchemaUtil.getDeepCordraSchemaProperty(
            editor.schema,
            "type",
            "handleReference",
            "types"
        );
        const excludeTypes = SchemaUtil.getDeepCordraSchemaProperty(
            editor.schema,
            "type",
            "handleReference",
            "excludeTypes"
        );
        const prepend = SchemaUtil.getDeepCordraSchemaProperty(
            editor.schema,
            "type",
            "handleReference",
            "prepend"
        );
        const prependHandleMintingConfigPrefix = SchemaUtil.getDeepCordraSchemaProperty(
            editor.schema,
            "type",
            "handleReference",
            "prependHandleMintingConfigPrefix"
        );
        const handleRefSearchSelector = new HandleRefSearchSelector(textInput, (v) => onPopoverItemChange(v), types, excludeTypes, prepend, prependHandleMintingConfigPrefix);
        editor.jsoneditor.watch(editor.path, () => handleRefSearchSelector.getTargetObject());
    }
});

JSONEditor.defaults.resolvers.unshift((schema) => {
    if (SchemaUtil.getDeepCordraSchemaProperty(schema, 'type', 'handleReference')) {
        return "handleReferenceType";
    }
});

JSONEditor.defaults.editors.suggestedVocabulary = JSONEditor.defaults.editors.string.extend({
    build() {
        this._super();
        const editor = this;
        const textInput = $(this.input);
        this.suggestedVocabularySelector = new SuggestedVocabularySelector(textInput, editor);
    },
    enable() {
        this.suggestedVocabularySelector.enable();
        this._super();
    },
    disable() {
        this.suggestedVocabularySelector.disable();
        this._super();
    }
});

JSONEditor.defaults.resolvers.unshift((schema) => {
    if (SchemaUtil.getDeepCordraSchemaProperty(schema, 'type', 'suggestedVocabulary')) {
        return "suggestedVocabulary";
    }
});


JSONEditor.defaults.editors.autoGeneratedField = JSONEditor.defaults.editors.string.extend({
    build() {
        this._super();
        const editor = this;
        const textInput = $(this.input);
        new AutoGeneratedField(textInput, editor);
    }
});

JSONEditor.defaults.resolvers.unshift((schema) => {
    const fieldNode = SchemaUtil.getDeepCordraSchemaProperty(schema, 'type', 'autoGeneratedField');
    if (fieldNode) {
        return "autoGeneratedField";
    }
});

JSONEditor.defaults.editors.jsonEditor = JSONEditor.defaults.editors.string.extend({
    build() {
        const self = this;
        this._super();
        this.source_code = false;
        const editor = this;
        const textInput = $(this.input);
        this.containerJQuery = $('<div style="height: 500px; display:block; width:100%;"/>');
        textInput.after(this.containerJQuery);
        textInput.hide();
        const container = this.containerJQuery[0];
        const options = {
            ace,
            theme: 'ace/theme/textmate',
            mode: 'code',
            //modes: ['code'], // allowed modes
            onError(err: Error) {
                APP.notifications.alertError(err.toString());
            },
            onChange() {
                self.refreshValue();
                if (self.watch_listener) self.watch_listener();
                self.jsoneditor.notifyWatchers(self.path);
                if (self.parent) self.parent.onChildEditorChange(self);
                else self.jsoneditor.onChange();
            }
        } as JSONEditorOptions;
        this.jsonEditorOnline = new JsonEditorOnline(container, options);
    },
    refreshValue() {
        try {
            this.value = this.jsonEditorOnline.get();
            this.serialized = JSON.stringify(this.value);
        } catch (e) {
            // ignore
        }
    },
    setValue(value, initial, from_template) {
        const self = this;

        if (this.template && !from_template) {
            return;
        }

        value = value || null;
        if (JSON.stringify(value) === this.serialized) return;

        this.jsonEditorOnline.set(value);

//        var changed = from_template || this.getValue() !== value;
//
        this.refreshValue();

//        if(changed) {
        if (self.parent) self.parent.onChildEditorChange(self);
        else self.jsoneditor.onChange();
//        }

        this.watch_listener();
        this.jsoneditor.notifyWatchers(this.path);
    },
    enable() {
        this.jsonEditorOnline.aceEditor.setReadOnly(false);
        this.containerJQuery.find('.ace_content').css('background-color', '');
        this._super();
    },
    disable() {
        this.jsonEditorOnline.aceEditor.setReadOnly(true);
        this.containerJQuery.find('.ace_content').css('background-color', '#eee');
        this._super();
    },
    destroy() {
        this.jsonEditorOnline.destroy();
        this._super();
    }
});

JSONEditor.defaults.resolvers.unshift((schema) => {
    const format = JsonUtil.getDeepProperty(schema, 'format');
    if ('json' === format) {
        return "jsonEditor";
    }
});

JSONEditor.defaults.themes.bootstrap3 = JSONEditor.defaults.themes.bootstrap3.extend({
    getButton(text, icon, title) {
        const el = this._super(text, icon, title);
        el.className += ' btn-sm';
        return el;
    }
});

export { default as JSONEditor } from '@json-editor/json-editor';
