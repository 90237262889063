/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck - TODO figure out cordra client fetch vs node-fetch incompatibility
import {
    AccessControlList,
    AuthResponse,
    CordraClient,
    CordraObject,
    Metadata,
    Options,
    ProgressCallback,
    QueryParams,
    SearchResults,
    SortField
} from '@cnri/cordra-client';
import { JsonSchema } from 'tv4';
import { VersionInfo } from '@cnri/cordra-client/dist/types/client/Interfaces';
import { AuthenticatorWidget, CustomAuthenticationConfig } from '../AuthenticatorWidget';
import { SubscriptionBanner } from '../SubscriptionBanner';
import { SchemasEditor } from '../admin/SchemasEditor';
import { UiConfigEditor } from '../admin/UiConfigEditor';
import { AuthConfigEditor } from '../admin/AuthConfigEditor';
import { HandleMintingConfig, HandleMintingConfigEditor } from '../admin/HandleMintingConfigEditor';
import { NetworkAndSecurity } from '../admin/NetworkAndSecurity';
import { DesignJavaScriptEditor } from '../admin/DesignJavaScriptEditor';
import { HtmlPageViewer } from './HtmlPageViewer';
import { AboutInfo } from './AboutInfo';
import { NavBar, NavLink } from './NavBar';
import { SchemaExtractorFactory } from './SchemaExtractor';
import { SearchWidget } from './SearchWidget';
import { Notifications } from './ToastrNotifications';
import ObjectEditor from './ObjectEditor';
import { ModalYesNoDialog } from './ModalYesNoDialog';
import { JsonUtil } from './JsonUtil';
import * as ObjectCloner from './ObjectCloner';
import { MethodsList } from './ObjectMethods';

interface InitData {
    version: Record<string, string>;
    design: Record<string, any>;
    isActiveSession: boolean;
    typesPermittedToCreate: string[];
    username?: string;
    userId?: string;
}

export class CordraUiMain {
    private schemas: Record<string, JsonSchema> = {};
    // ID to type map
    private schemaIds: Record<string, string> = {};
    private baseUriToNameMap: Record<string, string> = {};
    private nameToBaseUriMap: Record<string, string> = {};
    private refToNormalizedSchemaMap: Record<string, JsonSchema | undefined> = {};
    private readonly cordra: CordraClient;

    private uiConfig: Record<string, any> = {};
    private version: Record<string, string> = {};
    private handleMintingPrefix?: string;
    readonly notifications: Notifications;
    private searchWidget!: SearchWidget;
    private objectId?: string;
    private editor?: ObjectEditor;
    private fragmentJustSetInCreate?: string;

    private readonly editorDiv: JQuery;
    private readonly searchDiv: JQuery;
    private readonly htmlContentDiv: JQuery;
    private readonly aboutInfoDiv: JQuery;
    private navBar!: NavBar;

    private authConfig: unknown;

    private design!: Record<string, string>;

    private authWidget!: AuthenticatorWidget;

    // Admin sections
    private readonly schemasDiv: JQuery;
    private schemasEditor!: SchemasEditor;
    private uiConfigEditor!: UiConfigEditor;
    private readonly uiConfigDiv: JQuery;
    private readonly authConfigDiv: JQuery;
    private authConfigEditor!: AuthConfigEditor;
    private readonly handleMintingConfigDiv: JQuery;
    private handleMintingConfigEditor!: HandleMintingConfigEditor;
    private readonly designJavaScriptDiv: JQuery;
    private designJavaScriptEditor!: DesignJavaScriptEditor;
    private readonly networkAndSecurityDiv: JQuery;
    private networkAndSecurity!: NetworkAndSecurity;
    private readonly sections: Record<string, JQuery> = {};

    constructor(baseUri: string) {
        this.cordra = new CordraClient(baseUri);
        this.retrieveCordraOptions();

        this.editorDiv = $('#editor');
        this.searchDiv = $('#search');
        this.htmlContentDiv = $('#htmlContent');
        this.aboutInfoDiv = $('#aboutInfo');

        // admin sections
        this.schemasDiv = $('#schemas');
        this.uiConfigDiv = $('#ui');
        this.authConfigDiv = $('#authConfig');
        this.handleMintingConfigDiv = $('#handleRecords');
        this.networkAndSecurityDiv = $('#networkAndSecurity');
        this.designJavaScriptDiv = $('#designJavaScript');

        this.sections.schemas = this.schemasDiv;
        this.sections.ui = this.uiConfigDiv;
        this.sections.authConfig = this.authConfigDiv;
        this.sections.handleRecords = this.handleMintingConfigDiv;
        this.sections.networkAndSecurity = this.networkAndSecurityDiv;
        this.sections.designJavaScript = this.designJavaScriptDiv;

        this.notifications = new Notifications();

        const initUri = this.getBaseUri() + 'initData';
        this.cordra.buildAuthHeadersReturnDetails()
            .then((headersObj) => {
                if (headersObj.unauthenticated) {
                    this.storeCordraOptions({});
                    return fetch(initUri)
                        .then(CordraClient.checkForErrors);
                } else if (!headersObj.isStoredToken) {
                    return fetch(initUri, {
                        headers: headersObj.headers
                    })
                        .then(CordraClient.checkForErrors);
                } else {
                    return fetch(initUri, {
                        headers: headersObj.headers
                    })
                        .then(CordraClient.checkForErrors)
                        .catch((error) => {
                            if (error.status !== 401) return Promise.reject(error);
                            this.storeCordraOptions({});
                            return fetch(initUri)
                                .then(CordraClient.checkForErrors);
                        });
                }
            })
            .then(this.getResponseJson)
            .then((data: InitData) => this.onGotInitData(data))
            .catch(console.error);
        $(window).on('resize', () => this.onResize());

        this.onErrorResponse = this.onErrorResponse.bind(this);
    }

    getBaseUri(): string {
        return this.cordra.baseUri;
    }

    retrieveCordraOptions(): void {
        const localOptions = localStorage.getItem('cordraOptions');
        if (localOptions) {
            this.cordra.defaultOptions = JSON.parse(localOptions);
        } else {
            this.cordra.defaultOptions = {};
        }
    }

    storeCordraOptions(options: Options): void {
        localStorage.setItem('cordraOptions', JSON.stringify(options));
        this.cordra.defaultOptions = options;
    }

    getResponseJson(response: Response): any {
        return response.json();
    }

    onResize(): void {
        if (this.editor) {
            this.editor.resizeRelationshipsGraph();
        }
    }

    checkForDuplicateSchemaNames(schemaIds: Record<string, string>): void {
        const nameToIdMap: Record<string, string[]> = {};
        for (const schemaId in schemaIds) {
            const name = schemaIds[schemaId];
            let idsForName = nameToIdMap[name];
            if (!idsForName) {
                idsForName = [];
                nameToIdMap[name] = idsForName;
            }
            idsForName.push(schemaId);
        }
        for (const schemaName in nameToIdMap) {
            const idsForSchemaName = nameToIdMap[schemaName];
            if (idsForSchemaName.length > 1) {
                const message = `More than one schema has the same name '${schemaName}': ${JSON.stringify(idsForSchemaName)}`;
                this.notifications.alertError(message);
                break;
            }
        }
    }

    initializeSchemas(response: InitData): void {
        this.design.schemas = response.design.schemas;
        this.schemas = response.design.schemas;
        this.schemaIds = response.design.schemaIds;
        this.checkForDuplicateSchemaNames(this.schemaIds);
        this.baseUriToNameMap = {};
        for (const key in response.design.baseUriToNameMap) {
            this.baseUriToNameMap[this.makeBaseUriAbsolute(key)] = response.design.baseUriToNameMap[key];
        }
        this.nameToBaseUriMap = {};
        for (const key in response.design.nameToBaseUriMap) {
            this.nameToBaseUriMap[key] = this.makeBaseUriAbsolute(response.design.nameToBaseUriMap[key] as string);
        }
        this.normalizeSchemasAndUpdateRefToNormalizedSchemaMap();
        SchemaExtractorFactory.newSchemas(this.refToNormalizedSchemaMap);
    }

    onGotInitData(initData: InitData): void {
        this.processInitDataResponse(initData);

        new SubscriptionBanner(
            $('#subscriptionBanner'),
            this.handleMintingPrefix
        );

        this.searchWidget = new SearchWidget(
            this.searchDiv,
            false,
            this.uiConfig.numTypesForCreateDropdown as number
        );

        const allowLogin = this.checkIfLoginAllowed(initData.design.allowInsecureAuthentication as boolean);
        this.authWidget = new AuthenticatorWidget(
            $('#authenticateDiv'),
            () => this.onAuthenticationStateChange(),
            initData.isActiveSession,
            initData.username,
            initData.userId,
            initData.typesPermittedToCreate,
            allowLogin,
            this.uiConfig.customAuthentication as CustomAuthenticationConfig
        );

        this.onAuthenticationStateChange();

        // At this point, the editor and objectId are still null, so onAuthenticationStateChange will
        // call handleNewWindowLocation for us
        window.onhashchange = () => this.handleOnhashchange();
    }

    processInitDataResponse(response: InitData): void {
        if (!response.design.uiConfig) {
            response.design.uiConfig = {
                title: 'Cordra',
                navBarLinks: []
            };
        }
        if (!response.design.handleMintingConfig) {
            response.design.handleMintingConfig = {
                prefix: 'test'
            };
        }
        if (!response.design.authConfig) {
            response.design.authConfig = {};
        }
        this.design = response.design;
        this.uiConfig = response.design.uiConfig;
        this.version = response.version;
        this.initializeSchemas(response);

        this.authConfig = response.design.authConfig;
        this.handleMintingPrefix = response.design.handleMintingConfig.prefix;

        $('.navbar-brand').text(this.uiConfig.title as string);
        $('title').text(this.uiConfig.title as string);

        const navBarElement = $('#navBar');
        navBarElement.empty();
        this.navBar = new NavBar(
            navBarElement,
            this.uiConfig.navBarLinks as NavLink[],
            this.schemas,
            this.design.schemaIds as unknown as Record<string, string>
        );

        const isAdminDisabled = !(
            response.isActiveSession && response.username === 'admin'
        );
        this.buildAdminWidgets(isAdminDisabled);

        if (response.userId === 'admin') {
            this.enableAdminControls();
        } else {
            this.disableAdminControls();
        }
    }

    checkIfLoginAllowed(allowInsecureLogin: boolean): boolean {
        if (allowInsecureLogin) return true;
        return location.protocol === 'https:';
    }

    buildAdminWidgets(isAdminDisabled: boolean): void {
        this.schemasDiv.empty();
        this.schemasEditor = new SchemasEditor(
            this.schemasDiv,
            this.design.schemas as unknown as Record<string, JsonSchema>,
            this.design.schemaIds as unknown as Record<string, string>,
            isAdminDisabled
        );
        this.uiConfigDiv.empty();
        this.uiConfigEditor = new UiConfigEditor(
            this.uiConfigDiv,
            this.design.uiConfig,
            isAdminDisabled
        );
        this.authConfigDiv.empty();
        this.authConfigEditor = new AuthConfigEditor(
            this.authConfigDiv,
            this.design.authConfig,
            isAdminDisabled
        );
        this.handleMintingConfigDiv.empty();
        this.handleMintingConfigEditor = new HandleMintingConfigEditor(
            this.handleMintingConfigDiv,
            this.design.handleMintingConfig as HandleMintingConfig,
            isAdminDisabled
        );
        this.networkAndSecurityDiv.empty();
        this.networkAndSecurity = new NetworkAndSecurity(
            this.networkAndSecurityDiv,
            isAdminDisabled
        );
        this.designJavaScriptDiv.empty();
        this.designJavaScriptEditor = new DesignJavaScriptEditor(
            this.designJavaScriptDiv,
            this.design,
            isAdminDisabled
        );
    }

    onAuthenticationStateChange(): void {
        const userId = this.authWidget.getCurrentUserId();
        if (userId === 'admin') {
            this.enableAdminControls();
        } else {
            this.disableAdminControls();
        }
        if (this.editor && this.objectId) {
            const currentObjectId = this.objectId;
            this.hideObjectEditor();
            this.resolveHandle(currentObjectId);
        } else {
            this.handleNewWindowLocation();
        }
        this.refreshDesign();
    }

    disableAdminControls(): void {
        this.navBar.hideAdminMenu();
        this.uiConfigEditor.disable();
        this.authConfigEditor.disable();
        this.handleMintingConfigEditor.disable();
        this.networkAndSecurity.disable();
        this.schemasEditor.disable();
        this.designJavaScriptEditor.disable();
    }

    enableAdminControls(): void {
        this.navBar.showAdminMenu();
        this.uiConfigEditor.enable();
        this.authConfigEditor.enable();
        this.handleMintingConfigEditor.enable();
        this.networkAndSecurity.enable();
        this.schemasEditor.enable();
        this.designJavaScriptEditor.enable();
    }

    handleOnhashchange(): void {
        this.handleNewWindowLocation();
    }

    handleNewWindowLocation(): void {
        const fragment = window.location.hash.substring(1);
        const expectedFragment = this.fragmentJustSetInCreate;
        delete this.fragmentJustSetInCreate;
        if (fragment === expectedFragment) {
            return;
        }

        this.htmlContentDiv.hide();
        this.aboutInfoDiv.hide();
        this.searchWidget.hideResults();
        this.hideObjectEditor();
        this.hideAllAdminSections();

        if (fragment && fragment !== '') {
            if (!fragment.startsWith('objects/?query=')) {
                this.searchWidget.clearInput();
            }
            if (fragment.startsWith('objects/')) {
                if (fragment.startsWith('objects/?query=')) {
                    const params = this.getParamsFromFragment(fragment);
                    const fragmentQuery = params.query;
                    const sortFieldsString = params.sortFields;
                    let sortFields;
                    if (sortFieldsString) {
                        sortFields = JSON.parse(sortFieldsString) as SortField[];
                    }
                    const facetsString = params.facets;
                    let facet;
                    if (facetsString) {
                        const facets = JSON.parse(facetsString);
                        facet = facets.facets[0]?.field as string;
                    }
                    const filterQueriesString = params.filterQueries;
                    let filter;
                    if (filterQueriesString) {
                        const filterQueries = JSON.parse(filterQueriesString) as string[];
                        filter = filterQueries[0] ?? undefined;
                    }
                    this.searchWidget.search(fragmentQuery, sortFields, facet, filter);
                } else {
                    const fragmentObjectId = this.getObjectIdFromFragment(fragment);
                    if (fragmentObjectId != null && fragmentObjectId !== '') {
                        if (fragmentObjectId !== this.objectId) {
                            this.resolveHandle(fragmentObjectId);
                        }
                    }
                }
            } else if (fragment.startsWith('urls/')) {
                const url = this.getUrlFromFragment(fragment);
                this.showHtmlPageFor({ url });
            } else if (fragment.startsWith('pages/')) {
                const url = this.getPageUrlFromFragment(fragment);
                this.showHtmlPageFor({ url, embedded: true });
            } else if (fragment.startsWith('about/')) {
                this.showAboutInfo();
            } else if (fragment.startsWith('create/')) {
                const prefix = 'create/';
                const type = decodeURIComponent(fragment.substring(prefix.length));
                this.createNewObject(type);
            } else if (this.sections[fragment]) {
                this.sections[fragment].show();
            }
        } else if (
            !window.location.href.includes('#') &&
            this.uiConfig.initialFragment
        ) {
            window.location.hash = this.uiConfig.initialFragment;
        }
    }

    hideAllAdminSections(): void {
        for (const id in this.sections) {
            this.sections[id].hide();
        }
    }

    encodeURIComponentPreserveSlash(s: string): string {
        return encodeURIComponent(s).replace(/%2F/gi, '/');
    }

    setCreateInFragment(type: string): void {
        window.location.hash = 'create/' + this.encodeURIComponentPreserveSlash(type);
    }

    setObjectIdInFragment(objectId?: string): void {
        if (!objectId) return;
        window.location.hash = 'objects/' + this.encodeURIComponentPreserveSlash(objectId);
    }

    setQueryInFragment(query: string, sortFields?: SortField[], facet?: string, filter?: string): void {
        let fragment = 'objects/?query=' + this.encodeURIComponentPreserveSlash(query);
        if (sortFields && sortFields.length > 0) {
            const sortFieldsString = this.encodeURIComponentPreserveSlashForSortFields(sortFields);
            fragment += '&sortFields=' + sortFieldsString;
        }
        if (facet && facet.length > 0 && filter && filter.length > 0) {
            const facets = {
                facets: [
                    { field: facet }
                ]
            };
            fragment += '&facets=' + JSON.stringify(facets);
            if (filter && filter.length > 0) {
                const filterFragment = [decodeURIComponent(filter)];
                fragment += `&filterQueries=${JSON.stringify(filterFragment)}`;
            }
        }
        window.location.hash = fragment;
    }

    encodeURIComponentPreserveSlashForSortFields(sortFields: SortField[]): string {
        const resultSortFields = [];
        for (const sortField of sortFields) {
            const resultSortField = {
                name: this.encodeURIComponentPreserveSlash(sortField.name),
                reverse: !!sortField.reverse
            };
            resultSortFields.push(resultSortField);
        }
        return JSON.stringify(resultSortFields);
    }

    clearFragment(): void {
        window.location.hash = '';
    }

    performSearchWidgetSearch(query?: string, sortFields?: SortField[], facet?: string, filterQueries?: string[]): void {
        if (!query) return;
        this.hideHtmlContent();
        this.hideAboutInfo();
        this.setQueryInFragment(query, sortFields, facet, filterQueries);
    }

    hideObjectEditor(): void {
        delete this.objectId;
        if (this.editor) this.editor.destroy();
        this.editorDiv.empty();
        this.editorDiv.hide();
        delete this.editor;
    }

    hideHtmlContent(): void {
        this.htmlContentDiv.empty();
        this.htmlContentDiv.hide();
    }

    hideAboutInfo(): void {
        this.aboutInfoDiv.hide();
    }

    showHtmlPageFor(options: { url: string }): void {
        delete this.objectId;
        if (this.editor) this.editor.destroy();
        this.editorDiv.empty();
        this.editorDiv.hide();
        this.htmlContentDiv.show();
        new HtmlPageViewer(this.htmlContentDiv, options);
    }

    showAboutInfo(): void {
        delete this.objectId;
        if (this.editor) this.editor.destroy();
        this.editorDiv.empty();
        this.editorDiv.hide();
        this.aboutInfoDiv.empty();
        this.aboutInfoDiv.show();
        new AboutInfo(this.aboutInfoDiv, this.version);
    }

    getUiConfig(): Record<string, any> {
        return this.uiConfig;
    }

    getPrefix(): string | undefined {
        return this.handleMintingPrefix;
    }

    getSchema(type: string): JsonSchema {
        return this.schemas[type];
    }

    getSchemaCount(): number {
        return Object.keys(this.schemas).length;
    }

    createNewObject(type: string): void {
        this.editorDiv.empty();
        delete this.objectId;
        const allowEdits = true;
        const allowDownloadPayloads = true;

        const contentPlusMeta = {
            type,
            content: {},
            metadata: {} as Metadata
        };

        const options = {
            contentPlusMeta,
            schema: this.schemas[type],
            type,
            objectJson: {},
            objectId: undefined,
            disabled: false,
            allowEdits,
            allowDownloadPayloads
        };
        if (this.editor) this.editor.destroy();
        this.editor = new ObjectEditor(this.editorDiv, options);
        this.editorDiv.show();
    }

    resolveHandle(objectId?: string): void {
        if (!objectId) return;
        const options = Object.assign(
            { includeResponseContext: true },
            this.cordra.defaultOptions
        );
        this.cordra
            .get(objectId, options)
            .then((response) => {
                this.onGotObject(response);
            })
            .catch(this.onErrorResponse);
    }

    // Just gets an object by id, does not start editing that object
    getObject(objectId: string, successCallback: (obj: CordraObject) => void, errorCallback: (err: any) => void): void {
        this.cordra
            .get(objectId)
            .then(successCallback)
            .catch(errorCallback);
    }

    getPayloadContent(
            objectId: string | undefined,
            payloadName: string,
            successCallBack: (blob: Blob) => void,
            errorCallback: (err: any) => void
    ): void {
        if (!objectId) return;
        this.cordra
            .getPayload(objectId, payloadName)
            .then(successCallBack)
            .catch(errorCallback);
    }

    getAclForCurrentObject(onGotAclSuccess: (acl: AccessControlList) => void): void {
        if (!this.objectId) return;
        this.cordra
            .getAclForObject(this.objectId)
            .then(onGotAclSuccess)
            .catch(this.onErrorResponse);
    }

    saveAclForCurrentObject(
            newAcl: AccessControlList,
            onSuccess?: (acl: AccessControlList) => void,
            onFail?: (err: any) => void
    ): void {
        if (!this.objectId) return;
        this.notifications.clear();
        this.cordra
            .updateAclForObject(this.objectId, newAcl)
            .then((res) => {
                this.notifications.alertSuccess('ACL for Object ' + this.objectId + ' saved.');
                if (onSuccess) onSuccess(res);
            })
            .catch((response) => {
                this.onErrorResponse(response, onFail);
            });
    }

    onGotObject(contentPlusMeta: CordraObject): void {
        this.notifications.clear();
        this.editorDiv.empty();
        this.editorDiv.show();
        this.objectId = contentPlusMeta.id!;
        const type = contentPlusMeta.type!;
        let permission;
        if (contentPlusMeta.responseContext) {
            permission = (contentPlusMeta.responseContext as Record<string, any>).permission;
            delete contentPlusMeta.responseContext;
        }

        this.setObjectIdInFragment(this.objectId);
        this.hideHtmlContent();
        this.hideAboutInfo();
        let allowEdits = false;
        if (permission === 'WRITE') {
            allowEdits = true;
        }
        let allowDownloadPayloads = false;
        if (permission === 'WRITE' || permission === 'READ_INCLUDING_PAYLOADS') {
            allowDownloadPayloads = true;
        }

        const schema = this.getSchema(type);
        const content = contentPlusMeta.content;
        const options = {
            contentPlusMeta,
            schema,
            type,
            objectJson: content,
            objectId: this.objectId,
            disabled: true,
            allowEdits,
            allowDownloadPayloads
        };
        if (this.editor) this.editor.destroy();
        this.editor = new ObjectEditor(this.editorDiv, options);
    }

    // This method does not fiddle the UI in any way.
    search(
            query: string,
            pageNum: number,
            pageSize: number,
            sortFields: string | SortField[] | undefined,
            onSuccess: (res: SearchResults<string | CordraObject>) => void,
            onError: (err: unknown) => void
    ): void {
        if (!pageNum) pageNum = 0;
        if (!pageSize) pageSize = -1;
        let parsedSortFields = sortFields;
        if (typeof sortFields === 'string') {
            parsedSortFields = this.getSortFieldsFromString(sortFields);
        }
        const params = {
            pageNum,
            pageSize,
            sortFields: parsedSortFields
        } as QueryParams;
        this.cordra
            .search(query, params)
            .then(onSuccess)
            .catch(onError);
    }

    // This method does not fiddle the UI in any way.
    searchWithParams(
            query: string,
            params?: QueryParams,
            onSuccess?: (res: SearchResults<string | CordraObject>) => void,
            onError?: (err: unknown) => void
    ): void {
        this.cordra
            .search(query, params)
            .then(onSuccess)
            .catch(onError);
    }

    getSortFieldsFromString(sortFieldsString: string): SortField[] {
        let sortFields: SortField[] = [];
        if (sortFieldsString) {
            if (sortFieldsString.trim().startsWith('[')) {
                sortFields = JSON.parse(sortFieldsString);
                return sortFields;
            }
            const fieldStrings = sortFieldsString.split(',');
            fieldStrings.forEach((value) => {
                const terms = value.split(' ');
                let reverse = false;
                if (terms.length > 1) {
                    if (terms[1].toUpperCase() === 'DESC') reverse = true;
                }
                sortFields.push({
                    name: terms[0],
                    reverse
                });
            });
        }
        return sortFields;
    }

    getRelationships(
            objectId: string,
            successCallback: (res: any) => void,
            errorCallback: (err: unknown) => void,
            outboundOnly: boolean
    ): void {
        let uri = `${this.cordra.baseUri}relationships/${objectId}`;
        if (outboundOnly) {
            uri += '?outboundOnly=true';
        }
        this.cordra.retryAfterTokenFailure(this.cordra.defaultOptions, (headers) => {
            return fetch(uri, {
                headers
            })
                .then(CordraClient.checkForErrors);
        })
            .then(this.getResponseJson)
            .then(successCallback)
            .catch(errorCallback);
    }

    deleteObject(objectId?: string): void {
        if (!objectId) return;
        const dialog = new ModalYesNoDialog(
            'Are you sure you want to delete this object?',
            (() => {
                this.yesDeleteCallback(objectId, this.schemaIds);
            }),
            () => this.noDeleteCallback()
        );
        dialog.show();
    }

    yesDeleteCallback(objectId: string, schemaIds: Record<string, string>): void {
        const isSchema = Object.keys(schemaIds).includes(objectId);
        this.cordra
            .delete(objectId)
            .then(() => {
                if (isSchema) this.refreshDesign();
                if (this.editor) this.editor.destroy();
                this.editorDiv.empty();
                delete this.editor;
                this.clearFragment();
                this.notifications.alertSuccess('Object ' + objectId + ' deleted.');
            })
            .catch(this.onErrorResponse);
    }

    noDeleteCallback(): void {
        //no-op
    }

    cloneCurrentObject(): void {
        if (!this.editor) return;
        const currentObjectJson = this.editor.getJsonFromEditor();
        const currentObjectType = this.editor.getType();
        const allowDownloadPayloads = this.editor.getAllowDownloadPayloads();

        const newObject = ObjectCloner.clone(
            currentObjectJson,
            currentObjectType,
            this.schemas[currentObjectType]
        );

        this.editorDiv.empty();
        this.clearFragment();
        delete this.objectId;

        const contentPlusMeta = {
            type: currentObjectType,
            content: newObject,
            metadata: {} as Metadata
        };

        const options = {
            contentPlusMeta,
            schema: this.schemas[currentObjectType],
            type: currentObjectType,
            objectJson: newObject,
            disabled: false,
            allowEdits: true,
            allowDownloadPayloads
        };
        if (this.editor) this.editor.destroy();
        this.editor = new ObjectEditor(this.editorDiv, options);
        this.editorDiv.show();
    }

    saveObject(
            cordraObject: CordraObject,
            errorCallback: (err: unknown) => void,
            progressCallback?: ProgressCallback): void {
        this.notifications.clear();
        const options = Object.assign(
            { includeResponseContext: true },
            this.cordra.defaultOptions
        );
        this.cordra
            .update(cordraObject, progressCallback, options)
            .then((obj) => this.onSaveSuccess(obj))
            .catch((response) => {
                this.onErrorResponse(response, errorCallback);
            });
    }

    onSaveSuccess(contentPlusMeta: CordraObject): void {
        this.editorDiv.empty();
        this.objectId = contentPlusMeta.id;
        const type = contentPlusMeta.type!;
        let permission;
        if (contentPlusMeta.responseContext) {
            permission = (contentPlusMeta.responseContext as Record<string, any>).permission;
            delete contentPlusMeta.responseContext;
        }
        this.setObjectIdInFragment(this.objectId);
        if (type === "Schema" || this.objectId === 'design') this.refreshDesign();

        let allowEdits = false;
        if (permission === "WRITE") {
            allowEdits = true;
        }
        let allowDownloadPayloads = false;
        if (permission === "WRITE" || permission === "READ_INCLUDING_PAYLOADS") {
            allowDownloadPayloads = true;
        }

        const content = contentPlusMeta.content;
        const options = {
            contentPlusMeta,
            schema: this.schemas[type],
            type,
            objectJson: content,
            objectId: this.objectId,
            disabled: true,
            allowEdits,
            allowDownloadPayloads
        };
        if (this.editor) this.editor.destroy();
        this.editor = new ObjectEditor(this.editorDiv, options);
        this.notifications.alertSuccess("Object " + this.objectId + " saved.");
    }

    publishVersion(
            objectId: string,
            onSuccess: (res: VersionInfo) => void,
            onFail?: (err: unknown) => void
    ): void {
        this.notifications.clear();
        this.cordra
            .publishVersion(objectId)
            .then((res) => {
                this.notifications.alertSuccess("Version published with id " + res.id);
                if (onSuccess) {
                    onSuccess(res);
                }
            })
            .catch((response) => {
                this.onErrorResponse(response, onFail);
            });
    }

    getVersionsFor(
            objectId: string | undefined,
            onSuccess: (res: VersionInfo[]) => void,
            onFail?: (err: unknown) => void
    ): void {
        if (!objectId) return;
        this.cordra
            .getVersionsFor(objectId)
            .then(onSuccess)
            .catch((response) => {
                this.onErrorResponse(response, onFail);
            });
    }

    listInstanceAndStaticMethods(
            objectId: string,
            type: string,
            onSuccess: (methods: MethodsList) => void,
            onFail?: (err: unknown) => void
    ): void {
        this.cordra.listMethods(objectId)
            .then((instanceMethods) => {
                if (type === "CordraDesign") {
                    const result = {
                        instanceMethods,
                        staticMethods: []
                    };
                    onSuccess(result);
                } else {
                    this.cordra.listMethodsForType(type, true)
                    .then((staticMethods) => {
                        const result = {
                            instanceMethods,
                            staticMethods
                        };
                        onSuccess(result);
                    })
                    .catch((response) => {
                        this.onErrorResponse(response, onFail);
                    });
                }
            })
            .catch((response) => {
                this.onErrorResponse(response, onFail);
            });
    }

    listMethods(
            objectId: string,
            onSuccess: (methods: string[]) => void,
            onFail: (err: unknown) => void
    ): void {
        this.cordra.listMethods(objectId)
            .then(onSuccess)
            .catch((response) => {
                this.onErrorResponse(response, onFail);
            });
    }

    listMethodsForType(
            type: string,
            onSuccess: (methods: string[]) => void,
            onFail: (err: unknown) => void
    ): void {
        this.cordra.listMethodsForType(type, true)
            .then(onSuccess)
            .catch((response) => {
                this.onErrorResponse(response, onFail);
            });
    }

    callMethod(
            objectId: string,
            method: string,
            params: unknown,
            onSuccess: (value: any) => void,
            onFail: (err: unknown) => void
    ): void {
        this.cordra
            .callMethod(objectId, method, params)
            .then(onSuccess)
            .catch((response) => {
                this.onErrorResponse(response, onFail);
            });
    }

    callMethodAsResponse(
            objectId: string,
            method: string,
            params: unknown,
            attributes: any,
            onSuccess: (resp: Response) => void,
            onFail?: (err: unknown) => void
    ): void {
        const options = { ...this.cordra.defaultOptions, attributes };
        this.cordra
            .callMethodAsResponse(objectId, method, params, options)
            .then(onSuccess)
            .catch((response) => {
                this.onErrorResponse(response, onFail);
            });
    }

    callMethodForType(
            type: string,
            method: string,
            params: unknown,
            onSuccess: (resp: any) => void,
            onFail: (err: unknown) => void
    ): void {
        this.cordra
            .callMethodForType(type, method, params)
            .then(onSuccess)
            .catch((response) => {
                this.onErrorResponse(response, onFail);
            });
    }

    callMethodForTypeAsResponse(
            type: string,
            method: string,
            params: unknown,
            attributes: any,
            onSuccess: (resp: Response) => void,
            onFail?: (err: unknown) => void
    ): void {
        const options = { ...this.cordra.defaultOptions, attributes };
        this.cordra
            .callMethodForTypeAsResponse(type, method, params, options)
            .then(onSuccess)
            .catch((response) => {
                this.onErrorResponse(response, onFail);
            });
    }

    createObject(
            cordraObject: CordraObject,
            suffix?: string,
            errorCallback?: (err: unknown) => void,
            progressCallback?: ProgressCallback
    ): void {
        this.notifications.clear();
        const options = Object.assign(
            { suffix },
            this.cordra.defaultOptions
        );
        this.cordra
            .create(cordraObject, progressCallback, options)
            .then((obj) => this.onCreateSuccess(obj))
            .catch((response) => {
                this.onErrorResponse(response, errorCallback);
            });
    }

    onCreateSuccess(contentPlusMeta: CordraObject): void {
        this.editorDiv.empty();
        this.objectId = contentPlusMeta.id;
        const type = contentPlusMeta.type!;
        this.setObjectIdInFragment(this.objectId);
        this.fragmentJustSetInCreate = window.location.hash.substring(1);
        if (type === "Schema" || this.objectId === 'design') this.refreshDesign();
        const allowDownloadPayloads = this.editor!.getAllowDownloadPayloads();
        const content = contentPlusMeta.content;
        const options = {
            contentPlusMeta,
            schema: this.schemas[type],
            type,
            objectJson: content,
            objectId: this.objectId,
            disabled: true,
            allowEdits: true,
            allowDownloadPayloads
        };
        if (this.editor) this.editor.destroy();
        this.editor = new ObjectEditor(this.editorDiv, options);
        this.notifications.alertSuccess("Object " + this.objectId + " saved.");
    }

    resolveNormalizedSchema(uri: string): JsonSchema | undefined {
        let baseUri = uri;
        let fragment = '';
        const hashIndex = uri.indexOf('#');
        if (hashIndex !== -1) {
            baseUri = uri.substring(0, hashIndex);
            fragment = uri.substring(hashIndex + 1);
        }
        const name = this.baseUriToNameMap[baseUri] || this.getNameFromCordraSchemasUri(baseUri);
        if (!name) return undefined;
        const normalizedSchema = this.schemas[name];
        if (!fragment || !normalizedSchema || typeof normalizedSchema !== 'object') return normalizedSchema;
        if (fragment && !fragment.startsWith('/')) {
            // TODO: $ref fragment is not a JSON pointer
            return undefined;
        }
        return JsonUtil.getJsonAtPointer(normalizedSchema, fragment) as JsonSchema;
    }

    getNameFromCordraSchemasUri(uri: string): string | undefined {
        if (!uri) return undefined;
        const lowercaseUri = uri.toLowerCase();
        if (lowercaseUri.startsWith("file:///")) uri = uri.substring(7);
        else if (lowercaseUri.startsWith("file://")) uri = uri.substring(6);
        else if (lowercaseUri.startsWith("file:/")) uri = uri.substring(5);
        if (!uri.startsWith("/cordra/schemas/")) return undefined;
        uri = uri.substring(16);
        if (uri.substring(uri.length - 12) === ".schema.json") uri = uri.substring(0, uri.length - 12);
        return decodeURIComponent(uri);
    }

    normalizeSchema(schema: JsonSchema | JsonSchema[], uri: string, addToRefToNormalizedSchemaMap: boolean = false): void {
        if (!uri || !schema || typeof schema !== 'object') return;
        if (Array.isArray(schema)) {
            for (let i = 0; i < schema.length; i++) {
                this.normalizeSchema(schema[i], uri, addToRefToNormalizedSchemaMap);
            }
        } else {
            if (typeof schema.$ref === 'string') {
                // if already normalized, done
                if (!schema.$ref.match(/^[-A-Za-z+.]*:/)) {
                    // not IE11 safe
                    schema.$ref = new URL(schema.$ref, uri).href;
                }
                if (addToRefToNormalizedSchemaMap) {
                    let theRef = schema.$ref;
                    if (theRef.indexOf('#') >= 0) theRef = theRef.substring(0, theRef.indexOf('#'));
                    this.refToNormalizedSchemaMap[theRef] = undefined;
                }

            }
            for (const key in schema) {
                if (key !== 'enum') {
                    this.normalizeSchema(schema[key] as JsonSchema, uri, addToRefToNormalizedSchemaMap);
                }
            }
        }
    }

    makeBaseUriAbsolute(uri: string): string {
        if (uri.match(/^[-A-Za-z+.]*:/)) return uri;
        return new URL(uri, "file:/").href;
    }

    getSchemaBaseUri(type: string, schemaCordraObject: CordraObject): string {
        if (this.nameToBaseUriMap[type]) return this.nameToBaseUriMap[type];
        if (schemaCordraObject.content.baseUri) return this.makeBaseUriAbsolute(schemaCordraObject.content.baseUri as string);
        return this.makeBaseUriAbsolute('/cordra/schemas/' + encodeURIComponent(type));
    }

    normalizeSchemasAndUpdateRefToNormalizedSchemaMap(): void {
        this.refToNormalizedSchemaMap = {};
        for (const type in this.schemas) {
            const uri = this.nameToBaseUriMap[type];
            // make the $refs fully qualified paths
            this.normalizeSchema(this.schemas[type], uri, true);
        }
        for (const key in this.refToNormalizedSchemaMap) {
            this.refToNormalizedSchemaMap[key] = this.resolveNormalizedSchema(key);
        }
    }

    saveUiConfig(uiConfig: Record<string, any>): void {
        this.notifications.clear();
        if (!this.authWidget.getIsActiveSession()) {
            this.notifications.alertError("Not authenticated.");
            return;
        }
        this.cordra
            .updateObjectProperty("design", "/uiConfig", uiConfig)
            .then(() => {
                $(".navbar-brand").text(uiConfig.title as string);
                $("title").text(uiConfig.title as string);
                this.refreshDesign();
                this.notifications.alertSuccess("UiConfig saved.");
            })
            .catch(this.onErrorResponse);
    }

    saveDesignJavaScript(designJavaScriptIsModule: boolean | undefined, designJavaScript: unknown): void {
        this.notifications.clear();
        if (!this.authWidget.getIsActiveSession()) {
            this.notifications.alertError("Not authenticated.");
            return;
        }
        // TODO read-modify-write
        this.cordra.get("design")
        .then(design => {
            if (designJavaScriptIsModule === undefined) delete design.content.javascriptIsModule;
            else design.content.javascriptIsModule = designJavaScriptIsModule;
            if (!designJavaScript) delete design.content.javascript;
            else design.content.javascript = designJavaScript;
            return this.cordra.update(design);
        }).then(() => {
            this.notifications.alertSuccess("Design JavaScript saved.");
        }).catch(this.onErrorResponse);
    }

    saveAdminPassword(password: string, options: Options): void {
        this.notifications.clear();
        if (!this.authWidget.getIsActiveSession()) {
            this.notifications.alertError("Not authenticated.");
            return;
        }
        this.cordra
            .changeAdminPassword(password, options)
            .then(() => {
                this.notifications.alertSuccess("Admin password saved.");
            })
            .then(() => {
                if (options.username !== 'admin') return;
                const newOptions = {
                    username: 'admin',
                    password
                };
                this.authenticate(newOptions).catch(this.onErrorResponse);
            })
            .catch(this.onErrorResponse);
    }

    saveHandleMintingConfig(handleMintingConfig: unknown): void {
        this.notifications.clear();
        if (!this.authWidget.getIsActiveSession()) {
            this.notifications.alertError("Not authenticated.");
            return;
        }
        this.cordra
            .updateObjectProperty(
                "design",
                "/handleMintingConfig",
                handleMintingConfig
            )
            .then(() => {
                this.notifications.alertSuccess("Handle minting config saved.");
            })
            .catch(this.onErrorResponse);
    }

    updateAllHandles(successCallback: () => void): void {
        this.notifications.clear();
        if (!this.authWidget.getIsActiveSession()) {
            this.notifications.alertError("Not authenticated.");
            return;
        }
        this.cordra
            .updateAllHandles()
            .then(() => {
                this.notifications.alertSuccess("Update in progress");
                if (successCallback) successCallback();
            })
            .catch(this.onErrorResponse);
    }

    getHandleUpdateStatus(successCallback: (res: any) => void): void {
        this.notifications.clear();
        this.cordra
            .getHandleUpdateStatus()
            .then((res) => {
                if (successCallback) successCallback(res);
            })
            .catch(this.onErrorResponse);
    }

    saveAuthConfig(authConfig: unknown): void {
        this.notifications.clear();
        if (!this.authWidget.getIsActiveSession()) {
            this.notifications.alertError("Not authenticated.");
            return;
        }
        this.cordra
            .updateObjectProperty("design", "/authConfig", authConfig)
            .then(() => {
                this.notifications.alertSuccess("Auth config saved.");
            })
            .catch(this.onErrorResponse);
    }

    refreshDesign(): void {
        this.cordra.retryAfterTokenFailure(this.cordra.defaultOptions, (headers) => {
            return fetch(this.getBaseUri() + "initData", {
                headers
            })
            .then(CordraClient.checkForErrors);
        })
        .then(this.getResponseJson)
        .then((response: InitData) => {
            this.processInitDataResponse(response);
            if (this.schemasEditor) {
                this.schemasEditor.refresh(this.schemas, this.schemaIds);
            }
            const types = response.typesPermittedToCreate;
            this.authWidget.setTypesPermittedToCreate(types);
            this.searchWidget.setAllowCreateTypes(types);
        })
        .catch(console.error);
    }

    loadObjects(objects: CordraObject[], deleteCurrentObjects: boolean): void {
        this.notifications.clear();
        if (!this.authWidget.getIsActiveSession()) {
            this.notifications.alertError("Not authenticated.");
            return;
        }
        $("#objectLoadingGif").show();
        $("#loadFromFileButton").prop("disabled", true);
        this.cordra
            .uploadObjects(objects, deleteCurrentObjects)
            .then(() => {
                $("#objectLoadingGif").hide();
                $("#loadFromFileButton").prop("disabled", false);
                this.refreshDesign();
                this.notifications.alertSuccess("Objects loaded.");
            })
            .catch((response) => {
                $("#objectLoadingGif").hide();
                $("#loadFromFileButton").prop("disabled", false);
                this.onErrorResponse(response);
            });
    }

    getIdForSchema(type: string): string | undefined {
        let id;
        Object.keys(this.schemaIds).forEach((key) => {
            if (this.schemaIds[key] === type) id = key;
        });
        return id;
    }

    saveSchema(schemaCordraObject: CordraObject, type: string): void {
        this.notifications.clear();
        if (!this.authWidget.getIsActiveSession()) {
            this.notifications.alertError("Not authenticated.");
            return;
        }
        if (schemaCordraObject.id) {
            this.cordra
                .update(schemaCordraObject)
                .then(() => {
                    this.refreshDesign();
                    this.notifications.alertSuccess("Schema " + type + " saved.");
                })
                .catch(this.onErrorResponse);
        } else {
            schemaCordraObject.type = "Schema";
            this.cordra
                .create(schemaCordraObject)
                .then(() => {
                    this.refreshDesign();
                    this.notifications.alertSuccess("Schema " + type + " created.");
                })
                .catch(this.onErrorResponse);
        }
    }

    deleteSchema(type: string): void {
        this.notifications.clear();
        if (!this.authWidget.getIsActiveSession()) {
            this.notifications.alertError("Not authenticated.");
            return;
        }
        const id = this.getIdForSchema(type);
        if (id) {
            this.cordra
                .delete(id)
                .then(() => {
                    this.refreshDesign();
                    this.notifications.alertSuccess("Schema " + type + " deleted.");
                    this.closeSchemaEditor();
                })
                .catch(this.onErrorResponse);
        }
    }

    closeSchemaEditor(): void {
        this.schemasEditor.refresh();
        this.schemasEditor.showSchemaEditorFor();
    }

    viewOrEditCurrentObject(disabled: boolean): void {
        if (!this.editor) return;
        this.editorDiv.empty();
        this.editorDiv.show();
        const type = this.editor.getType();
        const jsonObject = this.editor.getJsonFromEditor();
        const allowEdits = this.editor.getAllowEdits();
        const allowDownloadPayloads = this.editor.getAllowDownloadPayloads();
        const contentPlusMeta = this.editor.getContentPlusMeta();
        const options = {
            contentPlusMeta,
            schema: this.schemas[type],
            type,
            objectJson: jsonObject,
            objectId: this.objectId,
            disabled,
            allowEdits,
            allowDownloadPayloads
        };
        if (this.editor) this.editor.destroy();
        this.editor = new ObjectEditor(this.editorDiv, options);
    }

    getObjectId(): string | undefined {
        return this.objectId;
    }

    getObjectIdFromFragment(fragment: string): string {
        const path = "objects/";
        return decodeURIComponent(fragment.substring(path.length));
    }

    getParamsFromFragment(fragment: string): Record<string, string> {
        const prefix = "objects/?";
        const queryParamsString = fragment.substring(prefix.length);
        const paramsArray = queryParamsString.split("&");
        const params: Record<string, string> = {};
        for (const paramString of paramsArray) {
            const paramTokens = paramString.split("=");
            params[decodeURIComponent(paramTokens[0])] = decodeURIComponent(paramTokens[1]);
        }
        return params;
    }

    getUrlFromFragment(fragment: string): string {
        const path = "urls/";
        return fragment.substring(path.length);
    }

    getPageUrlFromFragment(fragment: string): string | null {
        const path = "pages/";
        const pageName = fragment.substring(path.length);
        if (!pageName) return null;
        const pageConfig = this.design.uiConfig.customPages[pageName];
        if (!pageConfig) return null;
        const objectId = pageConfig.objectId ?? 'design';
        const payloadName = pageConfig.payloadName ?? pageName;
        return `${this.cordra.baseUri}objects/${objectId}?payload=${payloadName}`;
    }

    getAccessToken(): Promise<string> {
        return this.cordra.buildAuthHeadersReturnDetails().then((headersObj) => {
            const authHeader = headersObj.headers.get("Authorization");
            if (!authHeader) return "";
            if (!authHeader.startsWith("Bearer ")) return "";
            const bearerToken = authHeader.substring(7);
            if (bearerToken.includes(".")) return "";
            return bearerToken;
        });
    }

    disableJsonEditorOnline(editor: typeof JsonEditorOnline): void {
        editor.aceEditor.container.style.backgroundColor = "rgb(238, 238, 238)";
        editor.aceEditor.setReadOnly(true);
    }

    enableJsonEditorOnline(editor: typeof JsonEditorOnline): void {
        editor.aceEditor.container.style.backgroundColor = "";
        editor.aceEditor.setReadOnly(false);
    }

    fixAceJavascriptEditor(editor: AceAjax.Editor): void {
        // This ceremony causes the ace editor to uses a new ES version, which means the
        // builtin jshint will no longer complain about things like async/await.
        // See https://github.com/ajaxorg/ace/issues/3160
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        editor.session.on('changeMode', (_: Event, session: any) => {
            if ("ace/mode/javascript" === session.getMode().$id) {
                if (session.$worker) {
                    session.$worker.send("setOptions", [{
                        esversion: 11,
                        esnext: false
                    }]);
                }
            }
        });
    }

    authenticate(options: Options): Promise<AuthResponse> {
        return this.cordra.authenticate(options);
    }

    getAuthenticationStatus(full: boolean = false): Promise<AuthResponse> {
        return this.cordra.getAuthenticationStatus(full);
    }

    changePassword(newPassword: string, options: Options): Promise<Response> {
        return this.cordra.changePassword(newPassword, options);
    }

    signOut(): Promise<AuthResponse> {
        return this.cordra.signOut();
    }

    onErrorResponse(response?: any, errorCallback?: (err: unknown) => void): void {
        if (!response) {
            this.notifications.alertError("Something went wrong.");
        } else if (typeof response === 'string') {
            this.notifications.alertError(response);
        } else if (response.status === 401) {
            this.storeCordraOptions({});
            this.authWidget.setUiToStateUnauthenticated();
            const message = response.statusText || "Authentication failed";
            this.notifications.alertError(message as string);
        } else if (response.status === 403) {
            const message = response.statusText || "Forbidden";
            this.notifications.alertError(message as string);
        } else if (response.message) {
            this.notifications.alertError(response.message as string);
        } else {
            response.json()
            .then((json: any) => {
                this.notifications.alertError(json.message as string);
            });
        }
        if (errorCallback) errorCallback(response);
    }
}
