import { SortField } from '@cnri/cordra-client';

export class SearchSortBy {
    private readonly onSortByChanged: (sortFields: SortField[]) => void;
    private readonly sortBySelect: JQuery;
    private readonly ascDesSelect: JQuery;
    private readonly customInput: JQuery;

    constructor(containerDiv: JQuery<HTMLDivElement>, onSortByChanged: (sortFields: SortField[]) => void) {
        this.onSortByChanged = onSortByChanged;
        const sortByForm = $(`
            <div class="col-md-12 nopadding">
                <form class="form-inline" role="form">
                    <label for="sortBySelect" class="">Sort By</label>
                    <select class="form-control" id="sortBySelect">
                        <option value="score">Relevance</option>
                        <option value="metadata/createdOn">Created On</option>
                        <option value="metadata/modifiedOn">Modified On</option>
                        <option value="Custom">Custom</option>
                    </select>
                    <select class="form-control" id="ascDesSelect">
                        <option>Ascending</option>
                        <option>Descending</option>
                    </select>
                    <input type="text" id="sortByCustomInput" class="form-control" placeholder="Custom sort fields in JSON" style="display: none; width: 50%;">
                </form>
            </div>
        `);
        this.sortBySelect = sortByForm.find('#sortBySelect').first();
        this.sortBySelect.on('change', () => this.onChange());
        this.ascDesSelect = sortByForm.find('#ascDesSelect').first();
        this.ascDesSelect.on('change', () => this.onChange());
        this.customInput = sortByForm.find('#sortByCustomInput').first();
        this.customInput.on('change', () => {
            const name = this.sortBySelect.find("option:selected").val();
            if (name === "Custom") {
                this.onChange();
            }
        });
        containerDiv.append(sortByForm);
    }

    onChange(): void {
        const name = this.sortBySelect.find("option:selected").val();
        if (name === "Custom") {
            this.showCustomInput();
            const customJson = this.customInput.val();
            if (!customJson) {
                // don't fire the change event when the user select Custom before they enter their custom json
                return;
            }
        } else {
            this.hideCustomInput();
        }
        const sortFields = this.getSortFields();
        this.onSortByChanged(sortFields);
    }

    showCustomInput(): void {
        this.customInput.show();
        this.ascDesSelect.hide();
    }

    hideCustomInput(): void {
        this.customInput.hide();
        this.ascDesSelect.show();
    }

    setSortFields(sortFields: SortField[]): void {
        if (sortFields && sortFields.length > 0) {
            if (sortFields.length === 1) {
                const sortField = sortFields[0];
                const name = sortField.name;
                if (name === "score" || name === "metadata/createdOn" || name === "metadata/modifiedOn") {
                    this.sortBySelect.val(name);
                    this.hideCustomInput();
                } else {
                    this.sortBySelect.val("Custom");
                    const customJson = JSON.stringify(sortFields);
                    this.customInput.val(customJson);
                    this.showCustomInput();
                }
                if (sortField.reverse) {
                    this.ascDesSelect.val("Descending");
                } else {
                    this.ascDesSelect.val("Ascending");
                }
            } else {
                this.sortBySelect.val("Custom");
                const customJson = JSON.stringify(sortFields);
                this.customInput.val(customJson);
                this.showCustomInput();
            }
        } else {
            const defaultSortFields = [
                {
                    name: "score",
                    reverse: false
                }
            ];
            this.setSortFields(defaultSortFields);
        }
    }

    getSortFields(): SortField[] {
        const name = this.sortBySelect.find("option:selected").val();
        const direction = this.ascDesSelect.find("option:selected").val();
        const customJson = this.customInput.val() as string;
        if (name === "Custom") {
            let sortFields = null;
            if (customJson) {
                sortFields = JSON.parse(customJson);
            }
            return sortFields;
        } else {
            const sortField = {
                name,
                reverse: false
            } as SortField;
            if (direction === "Descending") {
                sortField.reverse = true;
            }
            return [sortField];
        }
    }
}
