import { CordraObject, Payload } from '@cnri/cordra-client';
import { PayloadEditor } from "./PayloadEditor";

export class PayloadsEditor {
    private readonly payloadsEditorsList: PayloadEditor[] = [];
    private readonly payloadsContainerDiv: JQuery;
    private deletedPayloads: string[] = [];
    private table!: JQuery;

    constructor(
            containerDiv: JQuery,
            payloads: Payload[],
            disabled: boolean,
            allowDownloadPayloads: boolean
    ) {
        this.buildTable(containerDiv, disabled);

        this.payloadsContainerDiv = $("<div></div>");
        containerDiv.append(this.payloadsContainerDiv);
        if (payloads) {
            for (const payload of payloads) {
                const payloadTr = $("<tr></tr>");
                this.table.append(payloadTr);

                const isNew = false;
                const payloadEditor = new PayloadEditor(
                    this,
                    payloadTr,
                    payload,
                    disabled,
                    isNew,
                    allowDownloadPayloads
                );
                this.payloadsEditorsList.push(payloadEditor);
            }
        }
    }

    buildTable(containerDiv: JQuery, disabled: boolean): void {
        this.table = $('<table class="table"></table>');
        this.table.css("margin-bottom", "0");
        containerDiv.append(this.table);

        const colgroup = $(
            '<colgroup><col style="width:25%"><col style="width:25%"><col style="width:20%"><col style="width:15%"><col style="width:15%"></colgroup>'
        );
        this.table.append(colgroup);

        const thead = $("<thead></thead>");
        this.table.append(thead);

        const titleRow = $("<tr></tr>");
        thead.append(titleRow);

        const titleRowColumn = $('<th colspan="5"></th>');
        titleRowColumn.append("<span>Payloads</span");
        titleRow.append(titleRowColumn);

        if (!disabled) {
            const addPayloadButton = $(
                '<button class="btn btn-sm btn-primary"><i class="fa fa-plus"></i></button>'
            );
            titleRowColumn.append(addPayloadButton);
            addPayloadButton.on("click", (e) => this.addNewPayload(e));

            const addPayloadButtonSpan = $("<span></span>");
            addPayloadButton.append(addPayloadButtonSpan);
            addPayloadButtonSpan.text("Add");
        }

        const tbody = $("<tbody></tbody>");
        this.table.append(tbody);
    }

    addNewPayload(e: JQuery.ClickEvent): void {
        e.preventDefault();

        const payloadTr = $("<tr></tr>");
        this.table.append(payloadTr);

        const isNew = true;
        const payloadEditor = new PayloadEditor(this, payloadTr, null, false, isNew);
        this.payloadsEditorsList.push(payloadEditor);
    }

    getNextDefaultPayloadName(): string {
        if (this.payloadsEditorsList.length === 0) {
            return "payload";
        } else {
            let highestPayloadNameCount = 0;
            for (const payloadEditor of this.payloadsEditorsList) {
                const name = payloadEditor.getNameFromInputOrLabel();
                if (name && name.startsWith("payload")) {
                    const suffix = name.substring(7);
                    // Convert to number and trim fractional part with double bitwise operator
                    const n = ~~Number(suffix);
                    if (String(n) === suffix && n >= 0) {
                        if (n > highestPayloadNameCount) {
                            highestPayloadNameCount = n;
                        }
                    }
                }
            }
            return "payload" + (highestPayloadNameCount + 1);
        }
    }

    deletePayload(payloadEditor: PayloadEditor): void {
        const i = this.payloadsEditorsList.indexOf(payloadEditor);
        if (i < 0) return;
        this.payloadsEditorsList.splice(i, 1);
        if (!payloadEditor.isNew) {
            if (!this.deletedPayloads.includes(payloadEditor.getName())) {
                this.deletedPayloads.push(payloadEditor.getName());
            }
        }
    }

    appendFormData(formData: FormData): void {
        this.cleanUpDeletedPayloads();
        for (const payload of this.deletedPayloads) {
            formData.append("payloadToDelete", payload);
        }
        for (const payloadEditor of this.payloadsEditorsList) {
            const blob = payloadEditor.getBlob();
            if (blob) {
                formData.append(payloadEditor.getName(), blob);
            }
        }
    }

    appendCordraObject(cordraObject: CordraObject): void {
        this.cleanUpDeletedPayloads();
        if (this.deletedPayloads.length > 0) {
            cordraObject.payloadsToDelete = this.deletedPayloads;
        }
        const plds = [];
        for (const payloadEditor of this.payloadsEditorsList) {
            const blob = payloadEditor.getBlob();
            if (blob) {
                plds.push({
                    name: payloadEditor.getName(),
                    body: blob,
                    filename: blob.name,
                    mediaType: blob.type
                });
            }
        }
        if (plds.length > 0) {
            cordraObject.payloads = plds;
        }
    }

    // clean up deletedPayloads before building HTTP request, in case someone deletes then re-adds a payload of the same name
    cleanUpDeletedPayloads(): void {
        const map: Record<string, boolean> = {};
        for (const payload of this.deletedPayloads) {
            map[payload] = true;
        }
        for (const payloadEditor of this.payloadsEditorsList) {
            delete map[payloadEditor.getName()];
        }
        this.deletedPayloads = Object.keys(map);
    }
}
