import { CordraObject } from "@cnri/cordra-client";
import { JsonSchema } from "tv4";
import { JSONEditor } from '../cordra/JSONEditorCordraMods';
import { ObjectPreviewUtil } from "../ObjectPreviewUtil";
import { SchemaUtil } from "./SchemaUtil";

export class AutoGeneratedField {
    private readonly textInput: JQuery<HTMLInputElement>;

    constructor(textInput: JQuery<HTMLInputElement>, editor: typeof JSONEditor) {
        this.textInput = textInput;
        let fieldName;
        let prepend;
        const node = SchemaUtil.getDeepCordraSchemaProperty(editor.schema as JsonSchema, 'type', 'autoGeneratedField');
        if (typeof node === 'object') {
            const nodeObj = node as { type: string; prepend: string; prependHandleMintingConfigPrefix: boolean };
            fieldName = nodeObj.type;
            prepend = nodeObj.prepend;
            if (!prepend && nodeObj.prependHandleMintingConfigPrefix) {
                const prefix = APP.getPrefix();
                if (prefix) prepend = this.ensureSlash(prefix);
            }
        } else {
            fieldName = node;
        }
        textInput.prop("disabled", true);
        if (textInput.val() === "") {
            let placeholder = "Auto generated field " + fieldName;
            if (prepend) placeholder += " (omitting " + prepend + ")";
            textInput.attr("placeholder", placeholder);
        }
        if (fieldName === "createdBy" || fieldName === "modifiedBy") {
            editor.jsoneditor.watch(editor.path, () => this.getTargetObject());
        }
    }

    ensureSlash(prefix: string): string {
        if (prefix.length === 0) return '/';
        if (prefix.substring(prefix.length - 1) === '/') {
            return prefix;
        }
        return prefix + '/';
    }

    getTargetObject(): void {
        const targetObjectId = this.textInput.val() as string;
        if (targetObjectId && targetObjectId !== "admin") {
            APP.getObject(
                targetObjectId,
                (obj: CordraObject) => this.onGotTargetObjectObject(obj),
                (resp: unknown) => this.onGotTargetObjectError(resp));
        }
    }

    onGotTargetObjectError(res: unknown): void {
        console.log(res);
    }

    onGotTargetObjectObject(obj: CordraObject): void {
        const objectId = obj.id!;
        const type = obj.type!;
        this.renderTargetObjectPreview(obj.content, type, objectId);
    }

    renderTargetObjectPreview(targetObject: unknown, type: string, objectId: string): void {
        const link = $('<a></a>');
        this.textInput.after(link);
        const targetObjectSearchResult = {
            id: objectId,
            type,
            json: targetObject
        };
        const previewData = ObjectPreviewUtil.getPreviewData(targetObjectSearchResult);
        let linkText = objectId;
        for (const jsonPointer in previewData) {
            const previewDataItem = previewData[jsonPointer];
            if (previewDataItem.isPrimary) {
                linkText = previewDataItem.title + ": " + previewDataItem.previewJson;
            }
        }
        link.text(linkText);
        link.attr('href', "#objects/" + objectId);
    }
}
