import { JsonSchema } from 'tv4';
import { AccessControlList } from '@cnri/cordra-client';
import { JSONEditor } from '../cordra/JSONEditorCordraMods';

export class ObjectAclEditor {
    private readonly editor: typeof JSONEditor;
    private readonly editorDiv: JQuery;

    constructor(
            containerDiv: JQuery,
            acl: AccessControlList,
            types: string[],
            allowEdits: boolean) {
        const actionToolBar = $(
            '<div class="object-editor-toolbar col-md-12 pull-right"></div>'
        );
        containerDiv.append(actionToolBar);

        const writer = allowEdits;
        const reader = !allowEdits;
        const empty = this.isEmpty(acl);

        if (writer) {
            const saveAclButton = $(
                '<button class="btn btn-sm btn-primary"><i class="fa fa-check"></i></button>'
            );
            actionToolBar.append(saveAclButton);
            saveAclButton.on("click", () => this.onSaveAclClick());

            const saveAclButtonSpan = $("<span></span>");
            saveAclButton.append(saveAclButtonSpan);
            saveAclButtonSpan.text("Save ACL");
        }

        this.editorDiv = $('<div class="col-md-12"></div>');
        containerDiv.append(this.editorDiv);

        if (writer || (reader && !empty)) {
            const schema = this.getAclJsonSchema();
            if (types) {
                schema.properties.readers.items.cordra.type.handleReference.types = types;
                schema.properties.writers.items.cordra.type.handleReference.types = types;
                schema.properties.payloadReaders.items.cordra.type.handleReference.types = types;
                schema.properties.methods.additionalProperties.items.cordra.type.handleReference.types = types;
            }

            const options = {
                theme: "bootstrap3",
                iconlib: "fontawesome4",
                schema,
                startval: acl,
                disable_edit_json: true,
                disable_properties: true,
                disable_collapse: true,
                disabled: false,
                prompt_before_delete: false
            };
            JSONEditor.defaults.options.iconlib = "bootstrap3";
            JSONEditor.defaults.editors.object.options.disable_properties = false;
            JSONEditor.defaults.editors.object.options.disable_edit_json = true;
            JSONEditor.defaults.editors.object.options.disable_collapse = false;

            this.editor = new JSONEditor(this.editorDiv[0], options);
            if (reader) {
                this.editor.disable();
                this.editorDiv.addClass("hidden-buttons");
            }
        } else {
            this.editorDiv.append("<p>No access control list to show.</p>");
        }
    }

    isEmpty(acl: AccessControlList | undefined): boolean {
        if (acl === undefined) return true;
        if (acl.writers && acl.writers.length > 0) return false;
        return !(acl.readers && acl.readers.length > 0);
    }

    onSaveAclClick(): void {
        const newAcl = this.editor.getValue() as AccessControlList;
        APP.saveAclForCurrentObject(
            newAcl,
            undefined,
            (resp: Record<string, string>) => this.onSaveAclFail(resp)
        );
    }

    onSaveAclFail(res: Record<string, string>): void {
        console.log("acl save fail: " + res.status + " " + res.statusText);
    }

    getAclJsonSchema(): JsonSchema {
        return {
            type: "object",
            title: "Access Control List",
            required: [],
            additionalProperties: false,
            properties: {
                readers: {
                    type: "array",
                    format: "table",
                    title: "Readers",
                    uniqueItems: true,
                    items: {
                        type: "string",
                        title: "Reader",
                        cordra: {
                            type: {
                                handleReference: {
                                    types: ["User", "Group"]
                                }
                            }
                        }
                    }
                },
                writers: {
                    type: "array",
                    format: "table",
                    title: "Writers",
                    uniqueItems: true,
                    items: {
                        type: "string",
                        title: "Writer",
                        cordra: {
                            type: {
                                handleReference: {
                                    types: ["User", "Group"]
                                }
                            }
                        }
                    }
                },
                payloadReaders: {
                    type: "array",
                    format: "table",
                    title: "Payload Readers",
                    uniqueItems: true,
                    items: {
                        type: "string",
                        title: "Payload Reader",
                        cordra: {
                            type: {
                                handleReference: {
                                    types: ["User", "Group"]
                                }
                            }
                        }
                    }
                },
                methods: {
                    type: "object",
                    title: "Methods",
                    additionalProperties: {
                        type: "array",
                        items: {
                            type: "string",
                            cordra: {
                                type: {
                                    handleReference: {
                                        types: ["User", "Group"]
                                    }
                                }
                            }
                        }
                    }
                }
            }
        };
    }

    destroy(): void {
        if (this.editor) this.editor.destroy();
    }
}
