import 'corejs-typeahead';
import { CordraObject, QueryParams, SearchResults, SortField } from '@cnri/cordra-client';
import { SearchSortBy } from "./SearchSortBy";
import { SearchResultsPagination } from "./SearchResultsPagination";
import { SearchResult } from "./SearchResult";
import { SearchFilter } from './SearchFilter';

export class SearchWidget {
    private readonly searchInput: JQuery<HTMLInputElement>;
    private readonly paginationDiv: JQuery<HTMLDivElement>;
    private readonly paginationBottomDiv: JQuery<HTMLDivElement>;
    private readonly searchBarDiv: JQuery<HTMLDivElement>;

    private readonly searchOptionsToggleButton: JQuery<HTMLButtonElement>;
    private readonly sortByDiv: JQuery<HTMLDivElement>;
    private readonly sortBy: SearchSortBy;
    private readonly filterByDiv: JQuery<HTMLDivElement>;
    private readonly filterBy: SearchFilter;
    private searchOptionsVisible: boolean = false;

    private readonly resultsContainer: JQuery<HTMLDivElement>;
    private readonly resultsDiv: JQuery<HTMLDivElement>;
    private readonly pageSize: number = 10;
    private readonly retrieveJsonForm: JQuery<HTMLFormElement>;
    private readonly createButtonSpan: JQuery<HTMLSpanElement>;
    private readonly numTypesForCreateDropdown: number;
    private readonly allowCreate: boolean;

    constructor(containerDiv: JQuery, allowCreate: boolean, numTypesForCreateDropdown: number) {
        this.allowCreate = allowCreate;
        this.numTypesForCreateDropdown = numTypesForCreateDropdown;
        this.searchBarDiv = $('<div class="row search-bar"></div>');
        containerDiv.append(this.searchBarDiv);

        const form = $('<form class="form-inline" role="form"></form>');
        this.searchBarDiv.append(form);
        form.on("submit", () => false);

        const column = $('<div class="col-md-12 nopadding"/>');
        form.append(column);

        const searchInputGroup = $('<div class="input-group"></div>');
        column.append(searchInputGroup);

        const sortByButtonSpan = $(
            '<span class="input-group-btn" style="width:1%" data-toggle="tooltip" data-placement="top" title="Show/Hide Advanced Search Options"/>'
        ) as JQuery & { tooltip: () => void };
        sortByButtonSpan.tooltip();
        searchInputGroup.append(sortByButtonSpan);

        this.searchOptionsToggleButton = $('<button class="btn btn-primary" type="button"><i class="fa fa-sort"></i></button>');
        sortByButtonSpan.append(this.searchOptionsToggleButton);
        this.searchOptionsToggleButton.on("click", (e) => this.onSearchOptionsButtonClick(e));

        this.searchInput = $(
            '<input type="text" class="form-control" placeholder="Search">'
        );
        searchInputGroup.append(this.searchInput);
        this.searchInput.on("keypress", (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                this.onSearchButtonClick();
            }
        });

        const buttonSpan = $(
            '<span class="input-group-btn" style="width:1%"></span>'
        );
        searchInputGroup.append(buttonSpan);

        const searchButton = $(
            '<button class="btn btn-primary cordra-search-button" type="button"><i class="fa fa-search"></i><span>Search<span></button>'
        );
        buttonSpan.append(searchButton);
        searchButton.on("click", () => this.onSearchButtonClick());

        if (this.allowCreate) {
            this.createButtonSpan = $(
                '<span class="input-group-btn" style="width:1%"></span>'
            );
        } else {
            this.createButtonSpan = $(
                '<span class="input-group-btn" style="width:1%; display:none"></span>'
            );
        }

        searchInputGroup.append(this.createButtonSpan);

        this.sortByDiv = $('<div class="search-sort-by" style="display:none"></div>');
        this.filterByDiv = $('<div class="search-filter" style="display:none"></div>');
        this.searchBarDiv.append(this.sortByDiv);
        this.searchBarDiv.append(this.filterByDiv);
        this.sortBy = new SearchSortBy(this.sortByDiv, () => this.onSearchButtonClick());
        this.filterBy = new SearchFilter(this.filterByDiv, () => this.onSearchButtonClick(), this.searchInput);

        this.resultsContainer = $(
            '<div class="row search-results-container-with-controls" style="display:none"><div/>'
        );
        containerDiv.append(this.resultsContainer);

        const paginationContainerTop = $('<div class="pagination-controls-top"/>');
        this.resultsContainer.append(paginationContainerTop);

        const paginationContainerDiv = $('<div class="row"/>');
        paginationContainerTop.append(paginationContainerDiv);

        this.retrieveJsonForm = $(
            '<form style="display:none" method="POST" target="_blank"/>'
        );
        const accessTokenInput = $('<input type="hidden" name="access_token"/>');
        APP.getAccessToken()
            .then((accessToken: string) => {
                accessTokenInput.val(accessToken);
            })
            .catch(console.error);
        this.retrieveJsonForm.append(accessTokenInput);
        paginationContainerDiv.append(this.retrieveJsonForm);

        this.paginationDiv = $('<div class="col-md-11"></div>');
        paginationContainerDiv.append(this.paginationDiv);

        const retrieveJsonLink = $(
            '<div class="col-md-1"><a class="link"><i class="fa fa-external-link-alt"></i><span>JSON</span></a></div>'
        );
        retrieveJsonLink.on("click", (event) => {
            event.preventDefault();
            APP.getAccessToken()
                .then((accessToken: string) => {
                    accessTokenInput.val(accessToken);
                    this.retrieveJsonForm.trigger("submit");
                })
                .catch(console.error);
        });
        paginationContainerDiv.append(retrieveJsonLink);

        this.resultsDiv = $('<div class="search-results-container"></div>');
        this.resultsContainer.append(this.resultsDiv);

        const paginationContainerBottomDiv = $("<div/>");
        this.resultsContainer.append(paginationContainerBottomDiv);

        this.paginationBottomDiv = $('<div class="pagination-controls-bottom"></div>');
        paginationContainerBottomDiv.append(this.paginationBottomDiv);
    }

    setAllowCreateTypes(types?: string[]): void {
        this.createButtonSpan.empty();
        if (!types) {
            this.createButtonSpan.hide();
            return;
        }
        types = types.filter(type => type !== "CordraDesign");
        if (types.length === 0) {
            this.createButtonSpan.hide();
            return;
        }
        if (types.length === 1) {
            this.buildSingleCreateButton(this.createButtonSpan, types[0]);
        } else if (
            !this.numTypesForCreateDropdown ||
                types.length <= this.numTypesForCreateDropdown
        ) {
            this.buildCreateDropdown(this.createButtonSpan, types);
        } else {
            this.buildCreateTypeahead(this.createButtonSpan, types);
        }
        this.createButtonSpan.show();
    }

    buildSingleCreateButton(form: JQuery, type: string): void {
        const createButton = $(
            '<button class="btn btn-default cordra-create-button"></button>'
        );
        createButton.data("type", type);
        if (type) {
            createButton.text("Create " + type);
        } else {
            createButton.text("Create");
        }
        form.append(createButton);
        createButton.on("click", (e) => this.onCreateButtonClicked(e));
    }

    onCreateButtonClicked(e: JQuery.ClickEvent): void {
        e.preventDefault();
        const createButton = $(e.target);
        const type = createButton.data("type") as string;
        APP.setCreateInFragment(type);
    }

    substringMatcher(strs: string[]) {
        return (q: string, cb: (m: string[]) => void): void => {
            // an array that will be populated with substring matches
            const matches: string[] = [];
            // regex used to determine if a string contains the substring `q`
            const substrRegex = new RegExp(q, "i");

            // iterate through the pool of strings and for any string that
            // contains the substring `q`, add it to the `matches` array
            $.each(strs, (_, str) => {
                if (substrRegex.test(str)) {
                    matches.push(str);
                }
            });

            cb(matches);
        };
    }

    buildCreateTypeahead(form: JQuery, types: string[]): void {
        const input = $(
            '<input class="typeahead form-control" type="text" placeholder="Type to create">'
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as JQuery & { typeahead: any };
        form.append(input);
        types.sort();
        input.typeahead(
            {
                hint: true,
                highlight: true,
                minLength: 1
            },
            {
                name: "create",
                source: this.substringMatcher(types)
            }
        );

        input.on("typeahead:select", (_, selection) => {
            APP.setCreateInFragment(selection as string);
        });
    }

    buildCreateDropdown(form: JQuery, types: string[]): void {
        const dropdownDiv = $(
            '<div class="dropdown" style="display:inline-block;vertical-align:top"></div>'
        );
        form.append(dropdownDiv);

        const dropdownButton = $(
            '<button class="btn btn-secondary dropdown-toggle cordra-create-button" type="button" id="dropdownMenu1" data-toggle="dropdown">Create <span class="caret"></span></button>'
        );
        dropdownDiv.append(dropdownButton);

        const createList = $(
            '<ul class="dropdown-menu" role="menu" aria-labelledby="dropdownMenu1"></ul>'
        );
        dropdownDiv.append(createList);
        types.sort();
        for (let i = 0; i < types.length; i++) {
            const objectType = types[i];
            const typeTitle = objectType;

            const menuItem = $('<li role="presentation"></li>');
            const menuLink = $(
                '<a role="menuitem" tabindex="-1" href="#">' + typeTitle + "</a>"
            );
            menuLink.attr("data-objectType", objectType);
            menuItem.append(menuLink);
            createList.append(menuItem);
            menuLink.on("click", (e) => this.onCreateClicked(e));
        }
    }

    onCloseClick(): void {
        APP.clearFragment();
    }

    clearInput(): void {
        this.hideSearchOptions();
        this.searchInput.val("");
    }

    hideResults(): void {
        this.resultsContainer.hide();
    }

    showSearchOptions(): void {
        this.searchOptionsVisible = true;
        this.sortByDiv.show();
        this.filterByDiv.show();
    }

    hideSearchOptions(): void {
        this.searchOptionsVisible = false;
        this.sortByDiv.hide();
        this.filterByDiv.hide();
    }

    onSearchOptionsButtonClick(e: JQuery.ClickEvent): void {
        e.preventDefault();
        if (this.searchOptionsVisible) {
            this.hideSearchOptions();
        } else {
            this.showSearchOptions();
        }
        this.onSearchButtonClick();
    }

    onCreateClicked(e: JQuery.ClickEvent): void {
        e.preventDefault();
        const clickedItem = $(e.target);
        const objectType = clickedItem.attr("data-objectType") as string;
        APP.setCreateInFragment(objectType);
    }

    onSearchButtonClick(): void {
        let query = this.searchInput.val() as string;
        if ("" === query) {
            return;
        }
        if (this.isObjectId(query)) {
            query = "id:" + query;
        }

        let sortFields = undefined;
        let facet = undefined;
        let filter = undefined;
        if (this.searchOptionsVisible) {
            sortFields = this.sortBy.getSortFields();
            facet = this.filterBy.getFacet();
            filter = this.filterBy.getSelectedFilter();
        }
        APP.setQueryInFragment(query, sortFields, facet, filter);
    }

    search(query: string, sortFields?: SortField[], facet?: string, filter?: string): void {
        if (sortFields || facet || filter) {
            if (sortFields) this.sortBy.setSortFields(sortFields);
            if (facet || filter) this.filterBy.setFacet(facet, filter);
        }
        if ('' === query) {
            return;
        }
        let filterQueries: string[] | undefined;
        if (filter) {
            filterQueries = [ filter ];
        }
        this.searchInput.val(query);
        const params = {
            pageNum: 0,
            pageSize: this.pageSize,
            sortFields,
            filterQueries
        } as QueryParams;
        APP.searchWithParams(
            query,
            params,
            (response: SearchResults<string | CordraObject>) => {
                this.onSuccess(query, sortFields, filterQueries, response);
            },
            (resp: unknown) => this.onError(resp)
        );
    }

    isObjectId(str: string): boolean {
        const prefix = APP.getPrefix() as string;
        if (str.startsWith(prefix + "/")) {
            const suffix = str.substring(str.lastIndexOf(prefix));
            return !this.containsSpaces(suffix);
        } else {
            return false;
        }
    }

    containsSpaces(str: string): boolean {
        return str.lastIndexOf(" ", 0) === 0;
    }

    onSuccess(
            query: string,
            sortFields: SortField[] | undefined,
            filterQueries: string[] | undefined,
            response: SearchResults<string | CordraObject>
    ): void {
        APP.notifications.clear();
        this.paginationDiv.empty();
        this.paginationBottomDiv.empty();
        this.resultsDiv.empty();

        const results = response.results;
        if (response.size > this.pageSize || response.size === -1) {
            new SearchResultsPagination(
                this.paginationDiv,
                response.size,
                response.pageNum,
                response.pageSize,
                ((ev) => {
                    this.onPageClick(query, sortFields, filterQueries, ev);
                })
            );
            new SearchResultsPagination(
                this.paginationBottomDiv,
                response.size,
                response.pageNum,
                response.pageSize,
                ((ev) => {
                    this.onPageClick(query, sortFields, filterQueries, ev);
                })
            );
        }
        this.writeResultsToResultsDiv(results as CordraObject[]);
        this.retrieveJsonForm.attr(
            "action",
            this.getRestApiUriFor(query, response.pageNum, response.pageSize, sortFields)
        );
        this.resultsContainer.show();
    }

    getRestApiUriFor(query: string, pageNum: number, pageSize: number, sortFields?: SortField[]): string {
        let uri =
            APP.getBaseUri() + "objects/?query=" +
            encodeURIComponent(query) +
            "&pageNum=" +
            pageNum +
            "&pageSize=" +
            pageSize;
        if (sortFields) {
            uri = uri + "&sortFields=" + encodeURIComponent(JSON.stringify(sortFields));
        }
        return uri;
    }

    writeResultsToResultsDiv(results:CordraObject[]): void {
        this.resultsDiv.empty();

        const list = $('<div class="search-results-list"></div>');
        this.resultsDiv.append(list);

        if (results.length === 0) {
            const noResultsLabel = $("<label>No Results</label>");
            this.resultsDiv.append(noResultsLabel);
        }

        for (const result of results) {
            new SearchResult(
                list as JQuery<HTMLDivElement>,
                result,
                APP.getUiConfig(),
                (e) => this.onHandleClick(e)
            );
        }
    }

    onPageClick(
            query: string,
            sortFields: SortField[] | undefined,
            filterQueries: string[] | undefined,
            ev: JQuery.ClickEvent
    ): void {
        ev.preventDefault();
        const pageNum = $(ev.target).data("pageNumber") as number;
        const params = {
            pageNum,
            pageSize: this.pageSize,
            sortFields,
            filterQueries
        };
        APP.searchWithParams(
            query,
            params,
            (response: SearchResults<string | CordraObject>) => {
                this.onSuccess(query, sortFields, filterQueries, response);
            },
            (resp: unknown) => this.onError(resp)
        );
    }

    onHandleClick(e: JQuery.ClickEvent): void {
        e.preventDefault();
        const link = $(e.target);
        const handle = link.attr("data-handle") as string;
        APP.resolveHandle(handle);
    }

    onError(response: unknown): void {
        this.resultsDiv.empty();
        APP.onErrorResponse(response);
    }
}
