export class NetworkAndSecurity {
    private readonly unauthenticatedDiv: JQuery<HTMLDivElement>;
    private readonly authenticatedContainerDiv: JQuery<HTMLDivElement>;
    private readonly saveButton: JQuery<HTMLButtonElement>;
    private readonly toolBarDiv: JQuery<HTMLDivElement>;
    private oldAdminPasswordInput: JQuery<HTMLInputElement> | undefined;
    private newAdminPasswordInput: JQuery<HTMLInputElement> | undefined;

    constructor(containerDiv: JQuery, disabled: boolean) {
        const headerRow = $('<div class="row object-header"></div>');
        containerDiv.append(headerRow);

        const objectHeader = $('<div class="heading col-md-6"></div>');
        const objectIdHeading = $('<h3 class="editorTitle">Security</h3>');
        objectHeader.append(objectIdHeading);
        headerRow.append(objectHeader);

        this.toolBarDiv = $(
            '<div class="object-editor-toolbar col-md-6 pull-right"></div>'
        );
        headerRow.append(this.toolBarDiv);

        this.saveButton = $(
            '<button class="btn btn-sm btn-primary pull-right"><i class="fa fa-save"></i></button>'
        );
        this.toolBarDiv.append(this.saveButton);
        this.saveButton.on("click", () => this.saveAdminPassword());

        const saveButtonSpan = $("<span></span>");
        this.saveButton.append(saveButtonSpan);
        saveButtonSpan.text("Save");

        this.unauthenticatedDiv = $(
            '<div style="display:none" class="col-md-12"></div>'
        );
        containerDiv.append(this.unauthenticatedDiv);

        const notAdminMessage = $(
            "<p>You need to be authenticated as admin in order to modify these settings.</p>"
        );
        this.unauthenticatedDiv.append(notAdminMessage);

        this.authenticatedContainerDiv = $(
            '<div style="display:none" class="col-md-12"></div>'
        );
        containerDiv.append(this.authenticatedContainerDiv);
        this.buildAdminPasswordEditor();

        if (!disabled) {
            this.authenticatedContainerDiv.show();
            this.saveButton.show();
        } else {
            this.unauthenticatedDiv.show();
            this.saveButton.hide();
        }
    }

    onCloseClick(): void {
        APP.clearFragment();
    }

    enable(): void {
        this.authenticatedContainerDiv.show();
        this.saveButton.show();
        this.unauthenticatedDiv.hide();
    }

    disable(): void {
        this.authenticatedContainerDiv.hide();
        this.saveButton.hide();
        this.unauthenticatedDiv.show();
    }

    buildAdminPasswordEditor(): void {
        const adminPasswordDiv = $("<div></div>");
        this.authenticatedContainerDiv.append(adminPasswordDiv);

        const form = $('<form class="form-horizontal"></form>');
        adminPasswordDiv.append(form);

        const oldPassGroup = $('<div class="form-group row"></div>');
        form.append(oldPassGroup);

        const oldPassLabel = $(
            '<label for="oldAdminPasswordInput" class="col-sm-2 control-label">Old Admin Password</label>'
        );
        oldPassGroup.append(oldPassLabel);

        const oldPassDiv = $('<div class="col-sm-10"></div>');
        oldPassGroup.append(oldPassDiv);

        this.oldAdminPasswordInput = $(
            '<input type="password" class="form-control" id="oldAdminPasswordInput"/>'
        );
        oldPassDiv.append(this.oldAdminPasswordInput);

        const newPassGroup = $('<div class="form-group row"></div>');
        form.append(newPassGroup);

        const newPassLabel = $(
            '<label for="adminPasswordInput" class="col-sm-2 control-label">Admin Password</label>'
        );
        newPassGroup.append(newPassLabel);

        const newPassDiv = $('<div class="col-sm-10"></div>');
        newPassGroup.append(newPassDiv);

        this.newAdminPasswordInput = $(
            '<input type="password" class="form-control" id="adminPasswordInput"/>'
        );
        newPassDiv.append(this.newAdminPasswordInput);
        this.newAdminPasswordInput.val();
    }

    saveAdminPassword(): void {
        const oldPassword = this.oldAdminPasswordInput!.val() as string;
        const newPassword = this.newAdminPasswordInput!.val() as string;
        this.oldAdminPasswordInput!.val("");
        this.newAdminPasswordInput!.val("");
        const options = {
            username: "admin",
            password: oldPassword
        };
        APP.saveAdminPassword(newPassword, options);
    }
}
