import { JsonSchema } from "tv4";
import { SortField } from '@cnri/cordra-client';
import { QueryForm, QueryFormConfig } from "./QueryForm";

export interface NavLink {
    type: string;
    title?: string;
    url?: string;
    location?: string;
    query?: string;
    sortFields?: SortField[] | SortField;
    queryForm?: QueryFormConfig;
    links?: NavLink[];
}

export class NavBar {
    private adminDropdownLi?: JQuery;
    private navBarElement: JQuery;
    private readonly navBarConfig: NavLink[];

    constructor(
            navBarElement: JQuery,
            navBarConfig: NavLink[],
            schemasParam: Record<string, JsonSchema>,
            schemaIdsParam: Record<string, string>
    ) {
        this.navBarElement = navBarElement;
        this.navBarConfig = navBarConfig;
        this.buildConfigurableItems(schemasParam, schemaIdsParam);
        this.addAdminDropDown();
    }

    buildConfigurableItems(schemas: Record<string, JsonSchema>, schemaIds: Record<string, string>): void {
        for (const navLink of this.navBarConfig) {
            if (navLink.type === "url") {
                this.addUrlLink(navLink);
            } else if (navLink.type === "page") {
                this.addPageLink(navLink);
            } else if (navLink.type === "query") {
                this.addQueryLink(navLink);
            } else if (navLink.type === "typeDropdown") {
                this.addQueryTypeDropDown(navLink, schemas);
            } else if (navLink.type === "typeObjectsDropdown") {
                this.addTypeObjectsDropDown(navLink, schemaIds);
            } else if (navLink.type === "about") {
                this.addAboutLink(navLink);
            } else if (navLink.type === "menu") {
                this.addMenu(navLink);
            }
        }
    }

    addUrlLink(navLink: NavLink): void {
        const li = this.createUrlLink(navLink);
        this.navBarElement.append(li);
    }

    createUrlLink(navLink: NavLink): JQuery {
        const li = $("<li></li>");
        const link = $('<a target="_self"></a>');
        link.text(navLink.title!);
        link.attr("href", "#urls/" + navLink.url);
        li.append(link);
        return li;
    }

    addPageLink(navLink: NavLink): void {
        const li = this.createPageLink(navLink);
        this.navBarElement.append(li);
    }

    createPageLink(navLink: NavLink): JQuery {
        let location = navLink.location || '';
        if (!location.startsWith('#')) location = `#${location}`;
        const li = $("<li></li>");
        const link = $('<a target="_self"></a>');
        link.text(navLink.title || "Link");
        link.attr("href", location);
        li.append(link);
        return li;
    }

    addQueryLink(navLink: NavLink): void {
        const li = this.createQueryLink(navLink);
        this.navBarElement.append(li);
    }

    createQueryLink(navLink: NavLink): JQuery {
        const li = $("<li></li>");
        const link = $("<a href=# ></a>");
        link.text(navLink.title!);
        link.attr("data-query", navLink.query!);
        if (navLink.sortFields) {
            let sortFields = navLink.sortFields;
            if (typeof navLink.sortFields === 'string') {
                // This is to handle the legacy sort fields format case
                sortFields = APP.getSortFieldsFromString(navLink.sortFields);
            }
            const sortFieldsString = JSON.stringify(sortFields);
            link.attr("data-sort-fields", sortFieldsString);
        }
        if (navLink.queryForm) {
            const queryFormString = JSON.stringify(navLink.queryForm);
            link.attr("data-query-form", queryFormString);
            link.attr("data-title", navLink.title!);
            link.on("click", (e) => this.onQueryFormLinkClick(e));
        } else {
            link.on("click", (e) => this.onQueryLinkClick(e));
        }
        li.append(link);
        return li;
    }

    onQueryLinkClick(e: JQuery.ClickEvent): void {
        e.preventDefault();
        const link = $(e.target);
        const query = link.attr("data-query");
        const sortFieldsString = link.attr("data-sort-fields");
        let sortFields;
        if (sortFieldsString) {
            sortFields = JSON.parse(sortFieldsString) as SortField[];
        }
        APP.performSearchWidgetSearch(query, sortFields);
    }

    onQueryFormLinkClick(e: JQuery.ClickEvent): void {
        e.preventDefault();
        const link = $(e.target);
        const query = link.attr("data-query") as string;
        const sortFieldsString = link.attr("data-sort-fields");
        let sortFields: SortField[];
        if (sortFieldsString) {
            sortFields = JSON.parse(sortFieldsString);
        }
        const queryFormString = link.attr("data-query-form") as string;
        const queryFormConfig = JSON.parse(queryFormString) as QueryFormConfig;
        const title = link.attr("data-title") as string;
        const queryForm = new QueryForm(title, query, queryFormConfig, (resultQuery) => {
            APP.performSearchWidgetSearch(resultQuery, sortFields);
        });
        queryForm.show();
    }

    addAboutLink(navLink: NavLink): void {
        const li = this.createAboutLink(navLink);
        this.navBarElement.append(li);
    }

    createAboutLink(navLink: NavLink): JQuery {
        const li = $("<li></li>");
        const link = $('<a target="_self"></a>');
        link.text(navLink.title!);
        link.attr("href", "#about/");
        li.append(link);
        return li;
    }

    addMenu(navLink: NavLink): void {
        const dropdownLi = $('<li class="dropdown types"></li>');
        this.navBarElement.append(dropdownLi);
        const dropdownToggle = $('<a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false"> </a>');
        dropdownLi.append(dropdownToggle);
        let title = "Menu";
        if (navLink.title) {
            title = navLink.title;
        }
        dropdownToggle.text(title + " ");
        const caret = $('<span class="caret"></span>');
        dropdownToggle.append(caret);
        const dropdownList = $('<ul class="dropdown-menu" role="menu"></ul>');
        dropdownLi.append(dropdownList);
        for (const link of navLink.links || []) {
            if (link.type === "query") {
                const menuItem = this.createQueryLink(link);
                dropdownList.append(menuItem);
            } else if (link.type === "about") {
                const menuItem = this.createAboutLink(link);
                dropdownList.append(menuItem);
            } else if (link.type === "url") {
                const menuItem = this.createUrlLink(link);
                dropdownList.append(menuItem);
            } else if (link.type === "page") {
                const menuItem = this.createPageLink(link);
                dropdownList.append(menuItem);
            }
        }
    }

    addTypeObjectsDropDown(navLink: NavLink, schemaIds: Record<string, string>): void {
        const dropdownLi = $('<li class="dropdown types"></li>');
        this.navBarElement.append(dropdownLi);
        const dropdownToggle = $('<a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false"> </a>');
        dropdownLi.append(dropdownToggle);
        let title = "Types";
        if (navLink.title) {
            title = navLink.title;
        }
        dropdownToggle.text(title + " ");
        dropdownToggle.text(title + " ");
        const caret = $('<span class="caret"></span>');
        dropdownToggle.append(caret);
        const dropdownList = $('<ul class="dropdown-menu" role="menu"></ul>');
        dropdownLi.append(dropdownList);
        const typeToIdMap = this.invertSchemaIdsMap(schemaIds);
        const types = Object.keys(typeToIdMap);
        types.sort();
        for (const schemaName of types) {
            if (schemaName === "CordraDesign") continue;
            const id = typeToIdMap[schemaName];
            const linkName = schemaName;
            const menuItem = $("<li></li>");
            dropdownList.append(menuItem);
            const itemLink = $('<a target="_self"></a>');
            itemLink.text(linkName);
            itemLink.attr("href", "#objects/" + id);
            menuItem.append(itemLink);
        }
    }

    invertSchemaIdsMap(schemaIds: Record<string, string>): Record<string, string> {
        const typeToIdMap: Record<string, string> = {};
        const ids = Object.keys(schemaIds);
        for (const id of ids) {
            const typeName = schemaIds[id];
            typeToIdMap[typeName] = id;
        }
        return typeToIdMap;
    }

    addQueryTypeDropDown(navLink: NavLink, schemas: Record<string, JsonSchema>): void {
        const dropdownLi = $('<li class="dropdown types"></li>');
        this.navBarElement.append(dropdownLi);
        const dropdownToggle = $('<a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false"> </a>');
        dropdownLi.append(dropdownToggle);
        let title = "Show Only";
        if (navLink.title) {
            title = navLink.title;
        }
        dropdownToggle.text(title + " ");
        const caret = $('<span class="caret"></span>');
        dropdownToggle.append(caret);
        const dropdownList = $('<ul class="dropdown-menu" role="menu"></ul>');
        dropdownLi.append(dropdownList);
        const types = Object.keys(schemas);
        types.sort();
        for (const schemaName of types) {
            if (schemaName === "CordraDesign") continue;
            const linkName = schemaName;
            const menuItem = $("<li></li>");
            dropdownList.append(menuItem);
            const itemLink = $('<a target="_self"></a>');
            itemLink.text(linkName);
            itemLink.attr("href", "#objects/?query=type:" + encodeURIComponent('"' + schemaName + '"'));
            menuItem.append(itemLink);
        }
    }

    addAdminDropDown(): void {
        this.adminDropdownLi = $('<li class="dropdown" style="display:none"></li>');
        this.navBarElement.append(this.adminDropdownLi);
        const dropdownToggle = $('<a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false"> </a>');
        this.adminDropdownLi.append(dropdownToggle);
        const title = "Admin";
        dropdownToggle.text(title + " ");
        const caret = $('<span class="caret"></span>');
        dropdownToggle.append(caret);
        const dropdownList = $('<ul class="dropdown-menu" role="menu"></ul>');
        this.adminDropdownLi.append(dropdownList);
        dropdownList.append(this.createMenuItem("Types", "#schemas"));
        dropdownList.append(this.createMenuItem("Design Object", "#objects/design"));
        dropdownList.append(this.createMenuItem("Design JavaScript", "#designJavaScript"));
        dropdownList.append(this.createMenuItem("UI", "#ui"));
        dropdownList.append(this.createMenuItem("Authorization", "#authConfig"));
        dropdownList.append(this.createMenuItem("Handle Records", "#handleRecords"));
        dropdownList.append(this.createMenuItem("Security", "#networkAndSecurity"));
    }

    createMenuItem(name: string, href: string): JQuery {
        const menuItem = $("<li></li>");
        const itemLink = $('<a target="_self"></a>');
        itemLink.text(name);
        itemLink.attr("href", href);
        menuItem.append(itemLink);
        return menuItem;
    }

    hideAdminMenu(): void {
        this.adminDropdownLi?.hide();
    }

    showAdminMenu(): void {
        this.adminDropdownLi?.show();
    }
}
