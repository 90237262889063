import { default as JsonEditorOnline, JSONEditorOptions } from 'jsoneditor';
import { CordraObject } from '@cnri/cordra-client';

export class FullCordraObjectViewer {
    private readonly editor: JsonEditorOnline;

    constructor(containerDiv: JQuery, cordraObject: CordraObject) {
        const cordraObjectDiv = $('<div class="col-md-12 nopadding"></div>');
        containerDiv.append(cordraObjectDiv);

        const attributesDiv = $(
            '<div style="height: 500px; display:block; width:100%;"></div>'
        );
        cordraObjectDiv.append(attributesDiv);

        const options = {
            ace,
            theme: "ace/theme/textmate",
            mode: "code",
            modes: ["code", "tree"], // allowed modes
            onError(err: unknown) {
                console.error(err);
            }
        } as JSONEditorOptions;
        this.editor = new JsonEditorOnline(attributesDiv[0], options, cordraObject);
        APP.disableJsonEditorOnline(this.editor);
    }
}
