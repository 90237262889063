import { BlobUtil, Payload } from '@cnri/cordra-client';
import { VideoPlayer } from "./VideoPlayer";
import { AudioPlayer } from "./AudioPlayer";
import * as FileIconUtil from "./FileIconUtil";
import { PayloadsEditor } from './PayloadsEditor';

export class PayloadEditor {
    private nameInput?: JQuery;
    private nameLabel?: JQuery;
    private readonly fileInput: JQuery<HTMLInputElement>;

    private readonly tdName: JQuery;
    private readonly tdFilenameAndSize: JQuery;
    private readonly tdFileInput: JQuery;
    private readonly tdDelete: JQuery;

    private mediaTr?: JQuery;
    private textEditorTr?: JQuery;
    private readonly payloadTr: JQuery;
    private mediaContainer!: JQuery;
    private textEditor?: AceAjax.Editor;
    private isTextChanged: boolean = false;
    private revertButton!: JQuery;
    private editButton!: JQuery;
    private readonly parentPayloadsEditor: PayloadsEditor;
    private readonly allowDownloadPayloads: boolean;
    private readonly objectId?: string;
    private readonly payload: Payload | null;
    private isShowingTextEditor: boolean = false;

    readonly isNew: boolean;

    constructor(
            parentPayloadsEditor: PayloadsEditor,
            payloadTr: JQuery,
            payload: Payload | null,
            disabled: boolean,
            isNewParam: boolean,
            allowDownloadPayloads: boolean = false
    ) {
        this.parentPayloadsEditor = parentPayloadsEditor;
        this.isNew = isNewParam;
        this.allowDownloadPayloads = allowDownloadPayloads;
        this.payload = payload;
        this.payloadTr = payloadTr;
        this.tdName = $("<td></td>");
        this.tdFilenameAndSize = $("<td></td>");
        this.tdFileInput = $("<td></td>");
        this.tdDelete = $('<td colspan="2"></td>');

        this.payloadTr.append(this.tdName);
        this.payloadTr.append(this.tdFilenameAndSize);
        this.payloadTr.append(this.tdFileInput);
        this.payloadTr.append(this.tdDelete);

        this.objectId = APP.getObjectId();

        this.fileInput = $("<input/>");
        this.fileInput.attr("type", "file");

        this.buildControls(payload, disabled);

        if (!disabled) {
            this.tdFileInput.append(this.fileInput);
            this.prettifyThisFileInput(this.fileInput);
        }
    }

    getName(): string {
        if (this.payload) {
            return this.payload.name;
        } else {
            return (this.nameInput?.val() || 'unknown') as string;
        }
    }

    getNameFromInputOrLabel(): string | undefined {
        if (this.nameInput != null) {
            return this.nameInput.val() as string | undefined;
        } else {
            return this.nameLabel?.text();
        }
    }

    getBlob(): File | undefined {
        let file = this.fileInput?.[0].files?.[0];
        if (!file && this.isTextChanged) {
            const text = this.getCurrentText();
            if (!text) return undefined;
            const blob = new Blob([text]);
            const options = { type: this.payload!.mediaType };
            file = new File([blob], this.payload!.filename || 'unknown', options);
        }
        return file;
    }

    buildControls(pld: Payload | null, disabled: boolean): void {
        if (!disabled) {
            const closeButton = $(
                '<button class="btn btn-sm btn-danger pull-right"><i class="fa fa-trash"></i></button>'
            );
            this.tdDelete.append(closeButton);
            closeButton.on("click", () => this.onCloseClick());
        }
        if (this.isNew) {
            this.nameInput = $('<input type="text" style="width:100%" />');
            const payloadName = this.parentPayloadsEditor.getNextDefaultPayloadName();
            this.nameInput.val(payloadName);
            this.tdName.append(this.nameInput);
            this.nameInput.trigger("focus");
        } else if (pld) {
            this.nameLabel = $("<span></span>");
            this.tdName.append(this.nameLabel);
            this.nameLabel.text(pld.name);

            if (this.isVideo() || this.isAudio() || this.isImage()) {
                const playButton = $(
                    //'<button class="btn btn-sm btn-default pull-right"><span class="glyphicon glyphicon-play"></span></button>'
                    '<button class="btn btn-sm btn-default pull-right"><i class="fa fa-play"></i></button>'
                );
                this.tdDelete.append(playButton);
                playButton.on("click", () => this.showMediaPlayer(pld));
            }
            if (!disabled && this.isText()) {
                this.editButton = $(
                    '<button class="btn btn-sm btn-primary pull-right"><i class="fa fa-edit"></i></button>'
                );
                this.tdDelete.append(this.editButton);
                this.editButton.on("click", () => this.showTextEditor(pld.name));

                this.revertButton = $(
                    '<button class="btn btn-sm btn-warning pull-right" style="display:none"><i class="fa fa-undo"></i></button>'
                );
                this.tdDelete.append(this.revertButton);
                this.revertButton.on("click", () => this.revertTextEditor());

                const revertButtonSpan = $("<span></span>");
                this.revertButton.append(revertButtonSpan);
                revertButtonSpan.text("Revert");
            }

            const iconName = FileIconUtil.getFontAwesomeIconNameFor(
                pld.mediaType,
                pld.filename
            );
            const icon = $('<i class="fa fa-' + iconName + ' fa-1x"></i>');
            this.tdFilenameAndSize.append(icon);
            this.tdFilenameAndSize.append(" ");

            if (this.allowDownloadPayloads) {
                const downloadButton = this.buildDownloadButton(pld.filename || 'unknown');
                this.tdFilenameAndSize.append(downloadButton);
            } else {
                const filenameLabel = $("<span></span>");
                filenameLabel.text(pld.filename || 'unknown');
                this.tdFilenameAndSize.append(filenameLabel);
            }

            const sizeLabel = $('<p class="helpText"></p>');
            sizeLabel.text("[" + pld.size + " bytes]");
            this.tdFilenameAndSize.append(sizeLabel);
        }
    }

    isImage(): boolean {
        return !!this.payload && FileIconUtil.isImage(this.payload.mediaType, this.payload.filename);
    }

    isVideo(): boolean {
        return !!this.payload && FileIconUtil.isVideo(this.payload.mediaType, this.payload.filename);
    }

    isAudio(): boolean {
        return !!this.payload && FileIconUtil.isAudio(this.payload.mediaType, this.payload.filename);
    }

    isText(): boolean {
        return !!this.payload && FileIconUtil.isText(this.payload.mediaType, this.payload.filename);
    }

    isJson(): boolean {
        return !!this.payload && FileIconUtil.isJson(this.payload.mediaType, this.payload.filename);
    }

    isJavaScript(): boolean {
        return !!this.payload && FileIconUtil.isJavaScript(this.payload.mediaType, this.payload.filename);
    }

    isHtml(): boolean {
        return !!this.payload && FileIconUtil.isHtml(this.payload.mediaType, this.payload.filename);
    }

    async showMediaPlayer(payload: Payload): Promise<void> {
        if (this.mediaTr != null) {
            this.mediaTr.remove();
            delete this.mediaTr;
        } else {
            this.mediaTr = $("<tr></tr>");
            const td = $('<td colspan="5"></td>');
            const container = $('<div style="width:100%"></div>');
            this.payloadTr.after(this.mediaTr);
            this.mediaTr.append(td);
            td.append(container);
            let mediaUri = this.getUri();
            const accessToken = await APP.getAccessToken();
            if (accessToken) {
                mediaUri += "&access_token=" + accessToken;
            }
            if (this.isVideo()) {
                new VideoPlayer(container, mediaUri);
            } else if (this.isAudio()) {
                new AudioPlayer(container, mediaUri);
            } else if (this.isImage()) {
                const image = $(`<img alt="${payload.name}" style="width:20%">`);
                image.attr("src", mediaUri);
                container.append(image);
            }
        }
    }

    getCurrentText(): string | undefined {
        return this.textEditor?.getValue();
    }

    revertTextEditor(): void {
        if (this.textEditorTr) {
            this.textEditorTr.remove();
            delete this.textEditorTr;
            this.isShowingTextEditor = false;
            this.isTextChanged = false;
            delete this.textEditor;
            this.revertButton.hide();
        }
    }

    showTextEditor(payloadName: string): void {
        if (this.isShowingTextEditor) {
            this.textEditorTr!.hide();
            this.isShowingTextEditor = false;
        } else {
            if (this.textEditorTr) {
                this.textEditorTr.show();
            } else {
                this.textEditorTr = $("<tr></tr>");
                const td = $('<td colspan="5"></td>');
                this.mediaContainer = $('<div style="width:100%"></div>');
                this.payloadTr.after(this.textEditorTr);
                this.textEditorTr.append(td);
                td.append(this.mediaContainer);
                APP.getPayloadContent(
                    this.objectId,
                    payloadName,
                    (blob: Blob) => this.onGotPayloadContentSuccess(blob),
                    (err: unknown) => this.onGotPayloadError(err)
                );
            }
            this.isShowingTextEditor = true;
        }
    }

    onGotPayloadContentSuccess(blob: Blob): void {
        BlobUtil.readBlob(blob, "text")
            .then((blobText) => {
                const textEditorDiv = $('<div class="ace_editor"></div>');
                this.mediaContainer.append(textEditorDiv);
                const textEditor = ace.edit(textEditorDiv[0]);
                textEditor.setTheme("ace/theme/textmate");
                if (this.isJavaScript()) {
                    APP.fixAceJavascriptEditor(textEditor);
                    textEditor.getSession().setMode("ace/mode/javascript");
                } else if (this.isJson()) {
                    textEditor.getSession().setMode("ace/mode/json");
                } else if (this.isHtml()) {
                    textEditor.getSession().setMode("ace/mode/html");
                }
                textEditor.setOptions({
                    maxLines: Infinity,
                    minLines: 10
                });
                textEditor.$blockScrolling = Infinity;
                textEditor.setValue(blobText as string, -1);
                textEditor.getSession().on("change", () => {
                    this.isTextChanged = true;
                    this.revertButton.show();
                });
                this.textEditor = textEditor;
            })
            .catch((err) => this.onGotPayloadError(err));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onGotPayloadError(err: any): void {
        if (typeof err === 'object' && err.json) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            err.json().then((json: any) => {
                console.log(json);
            });
        } else {
            console.log(err);
        }
    }

    onCloseClick(): void {
        this.parentPayloadsEditor.deletePayload(this);
        this.payloadTr.remove();
        if (this.mediaTr) {
            this.mediaTr.remove();
        }
        if (this.textEditorTr) {
            this.textEditorTr.remove();
        }
    }

    buildDownloadButton(text: string): JQuery {
        const form = $('<form style="display:none" method="POST"/>');
        form.attr("action", () => this.getDownloadUri());
        const accessTokenInput = $('<input type="hidden" name="access_token"/>');
        form.append(accessTokenInput);
        const downloadButton = $('<a href="#"></a>');
        if (text) {
            downloadButton.text(text);
        } else {
            downloadButton.text("Download");
        }
        downloadButton.on("click", async (event) => {
            event.preventDefault();
            const accessToken = await APP.getAccessToken();
            accessTokenInput.val(accessToken);
            form.trigger("submit");
        });
        downloadButton.append(form);
        return downloadButton;
    }

    getUri(): string {
        if (!this.payload) return '';
        return (
            APP.getBaseUri() + "objects/" +
            this.objectId +
            "?payload=" +
            encodeURIComponent(this.payload.name).replace(/%2F/g, "/")
        );
    }

    getDownloadUri(): string {
        if (!this.payload) return '';
        return (
            APP.getBaseUri() + "objects/" +
            this.objectId +
            "?payload=" +
            encodeURIComponent(this.payload.name).replace(/%2F/g, "/") +
            "&disposition=attachment"
        );
    }

    prettifyThisFileInput(input: JQuery<HTMLInputElement>): void {
        if (input.css("opacity") === "0") return;
        input.css("opacity", "0");
        input.css("z-index", "-100");
        input.css("position", "fixed");
        input.css("left", "-10px");
        input.css("height", "1px");
        input.css("width", "1px");
        input.css("margin", "0");
        input.css("padding", "0");
        const textForButton = "Choose file";
        const button = $(
            '<button class="btn btn-sm btn-primary" type="button"><i class="fa fa-file"></i><span>' +
            textForButton +
            "</span></button>"
        );
        const span = $('<p class="helpText">No files chosen</p>');
        const div = $('<div class="hide-with-buttons"/>');
        div.append(button, span);
        input.before(div);
        button.off("click").on("click", (event) => {
            event.stopImmediatePropagation();
            input.trigger("click");
        });
        input.on("change", () => {
            if (input[0].files) {
                if (input[0].files.length === 0) {
                    span.text("No files chosen");
                } else if (input[0].files.length === 1) {
                    span.text(input[0].files[0].name);
                } else {
                    span.text(input[0].files.length + " files");
                }
            }
            this.revertTextEditor();
            if (this.editButton) this.editButton.hide();
        });
    }
}
