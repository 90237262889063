export class AboutInfo {
    constructor(containerDiv: JQuery, version: Record<string, string>) {
        const html = $(`
            <div id='version' style='text-align: center;'>
                <img width="80" src="./img/cordra-primary-blue-large.png" alt="logo">
                <h3>Cordra</h3>
                <p id='versionNumber'></p>
                <p id='versionTimestamp'></p>
                <p id='versionBuild'></p>
                <p><a href="#urls/openapi/index.html">DOIP HTTP API Docs</a></p>
            </div>
        `);
        containerDiv.append(html);

        const number = containerDiv.find('#versionNumber');
        const timestamp = containerDiv.find('#versionTimestamp');
        const build = containerDiv.find('#versionBuild');
        number.text("Version: " + version.number);
        timestamp.text("Date: " + version.timestamp);
        build.text("Build id: " + version.id);
    }
}
