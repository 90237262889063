import { FacetBucket } from '@cnri/cordra-client/dist/types/client/Interfaces';
import { CordraObject, QueryParams, SearchResults } from '@cnri/cordra-client';

export class SearchFilter {
    private readonly onSearchButtonClicked: () => void;
    private readonly searchInput: JQuery;
    private readonly facetInput: JQuery;
    private readonly filterSelect: JQuery;

    constructor(
            containerDiv: JQuery<HTMLDivElement>,
            onSearchButtonClicked: () => void,
            searchInput: JQuery
    ) {
        this.onSearchButtonClicked = onSearchButtonClicked;
        this.searchInput = searchInput;
        const filterForm = $(`
            <div class="col-md-12 nopadding">
                <div class="form-inline" role="form">
                    <label>
                        Facet On
                        <input type="text" class="form-control" placeholder="ex: /name"/>
                    </label>
                    <select class="form-control" style="display: none"></select>
                </div>
            </div>
        `);
        this.facetInput = filterForm.find('input').first();
        this.facetInput.on('change', (_, selectedFilter?: string) => this.getFiltersForFacet(selectedFilter));
        this.filterSelect = filterForm.find('select').first();
        this.filterSelect.on('change', () => this.onSearchButtonClicked());
        containerDiv.append(filterForm);
    }

    getFacet(): string | undefined {
        return this.facetInput.val() as string | undefined;
    }

    setFacet(facet?: string, selectedFilter?: string): void {
        if (facet && facet.length > 0) {
            this.facetInput.val(facet);
            this.facetInput.trigger('change', [ selectedFilter ]);
        }
    }

    getFiltersForFacet(selectedFilter?: string): void {
        this.filterSelect.empty();
        this.filterSelect.hide();
        const facet = this.facetInput.val() as string;
        if (!facet || facet.length === 0) {
            this.onSearchButtonClicked();
            return;
        }
        const params: QueryParams = {
            pageSize: 0,
            facets: [
                { field: facet }
            ]
        };
        let query = this.searchInput.val() as string;
        if (!query || query.length === 0) query = '*:*';
        APP.searchWithParams(
            query,
            params,
            (response: SearchResults<string | CordraObject>) => {
                const facets = response.facets || [];
                if (facets.length > 0 && facets[0].buckets) {
                    this.setFilterQueries(facets[0].buckets, selectedFilter);
                }
                this.onSearchButtonClicked();
            }
        );
    }

    setFilterQueries(facetBuckets: FacetBucket[], selectedFilter?: string): void {
        this.filterSelect.empty();
        this.filterSelect.hide();
        if (facetBuckets && facetBuckets.length > 0) {
            this.filterSelect.append(`<option></option>`);
            for (const bucket of facetBuckets) {
                const filter = APP.encodeURIComponentPreserveSlash(bucket.filterQuery!);
                const option = `<option value="${filter}">${bucket.value}</option>`;
                this.filterSelect.append(option);
            }
            if (selectedFilter && selectedFilter.length > 0) {
                this.filterSelect.val(APP.encodeURIComponentPreserveSlash(selectedFilter));
                this.filterSelect.trigger('change');
            }
            this.filterSelect.show();
        }
    }

    getSelectedFilter(): string | undefined {
        const filters = this.filterSelect.find("option:selected");
        let filter;
        if (filters.length > 0) {
            const val = $(filters[0]).val() as string;
            if (val && val.length > 0) {
                filter = val;
            }
        }
        return filter;
    }
}
